import React, { useState, useRef } from 'react';
import Calendar from 'react-calendar';
import { Link, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import './styles/Header.css';
import Modal from 'react-modal';
import api from '/app/frontend/src/api';
import { slugBoard } from './components/CustomContextToHeader';
import ChoiceOrganization from './ChoiceOrganizationPage';

const Header = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const publicVapidKey = process.env.REACT_APP_PUBLIC_VAPID_KEY;
    const location = useLocation();
    const [value, onChangeCalendar] = useState(new Date());
    const token = localStorage.getItem('token');
    const [user, setUser] = useState('');
    const [boardname, setBoardName] = useState('');
    const [modalIsOpenCalendar, setIsOpenCalendar] = useState(false);
    // const [numberoftasks, setTasksnumber] = useState([]);
    const [author, setauthor] = useState('');
    const [checkRole, setCheckRole] = useState(false);
    const [checkPermission, setCheckPermission] = useState(false);
    const [addRole, setAddRole] = useState(false);
    const [modalIsOpenPhone, setModalIsOpenPhone] = useState(false);
    const [modalIsOpenChangeOrganization, setIsOpenChangeorganization] = useState(false);
    const [modalIsOpenNotes, setIsOpenNotes] = useState(false);
    const [notes, setNotes] = useState([]);
    const [textNote, setTextNote] = useState('');
    const [linkNote, setLinkNote] = useState('');
    const [hhTime, setHHTime] = useState('00');
    const [mmTime, setMMTime] = useState('00');
    const handleChangeHHTime = (event) => {
      setHHTime(event.target.value);
    };
    const handleChangeMMTime = (event) => {
      setMMTime(event.target.value);
    };
    const handleChangelinkNote = (event) => {
      setLinkNote(event.target.value);
    };

    const handleChangeCalendar = (data) => {
      onChangeCalendar(data);
      closeModalCalendar();
    }
    function urlB64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
          .replace(/-/g, '+')
          .replace(/_/g, '/');
  
      const rawData = window.atob(base64);
      return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
    }
    function getsogl() {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
          navigator.serviceWorker.getRegistration().then(function (registration) {
              if (registration && registration.pushManager) {
                  console.log('Сервисный работник уже зарегистрирован. Подписка не требуется.');
                  return;
              }
              return Notification.requestPermission().then(function (permissionResult) {
                  if (permissionResult !== 'granted') {
                      throw new Error('Permission not granted.');
                  }
                  return navigator.serviceWorker.register('/service-worker.js');
              }).then(function (registration) {
                  return navigator.serviceWorker.ready;
              }).then(function (registration) {
                  return registration.pushManager.subscribe({
                      userVisibleOnly: true,
                      applicationServerKey: urlB64ToUint8Array(publicVapidKey)
                  });
              }).then(function (subscription) {
                  console.log("Подписка завершена, отправляю данные.");
                  const subscriptionData = {
                      endpoint: subscription.endpoint,
                      keys: {
                          p256dh: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh')))),
                          auth: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth'))))
                      }
                  };
                  return api.post(`${apiUrl}api/subscribe/`, subscriptionData, {
                      headers: {
                          'Authorization': 'Bearer ' + token
                      },
                  });
              }).then((response) => {
                  if (response.status === 201) {
                      console.log('Успешная подписка на уведомления.');
                  }
              }).catch(error => {
                  console.error('Ошибка во время подписки:', error);
              });
          }).catch(error => {
              console.error('Ошибка получения регистрации сервисного работника:', error);
          });
      } else {
          console.log("Push-уведомления или Service Worker не поддерживаются в этом браузере.");
      }
  }
  
    function closeModal() {
      setModalIsOpenPhone(false);
      setIsOpenChangeorganization(false);
      setIsOpenNotes(false);
    };
    const handleGoToBoards = (event) => {
        window.location.href = "/main";
    };
    function updateNotes() {
      api.get(`${apiUrl}api/v1/notes/`, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
      }).then((response) => {
        if (response.status === 200) {
          setIsOpenNotes(true);
          setNotes(response.data);
        }
      }).catch(error =>{
        console.error(error);
      });
    };
    const handleOpenNotes = (event) => {
      getsogl();
      closeModal();
      updateNotes()
    };
    const handleGoToBoardsNewPin = (e) => {
      if (e.button === 1) {
        e.preventDefault();
        window.open("/main");
      }
    };
    const handleGoToAuth = (event) => {
        window.location.href = "/login";
    };
    const handleGoToRegist = (event) => {
      window.location.href = "/registration";
    };
    const handleGoToProfile = (event) => {
        window.location.href = "/profile/" + user.username;
    };
    const handleGoToProfileNewPin = (e) => {
      if (e.button === 1) {
        e.preventDefault();
        window.open("/profile/" + user.username);
      }
    };
    const handleGoToRevertBoard = (event) => {
        window.location.href = `/boards/${slugBoard}`;
    };
    const handleGoToRevertBoardNewPin = (e) => {
      if (e.button === 1) {
        window.open(`/boards/${slugBoard}`);
      }
  };
  const closeModalCalendar = () => {
    setIsOpenCalendar(!modalIsOpenCalendar);
  }

  const textareaRef = useRef(null);

  const handleInput = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
    const handleGoToTask = (event) => {
        window.location.href = "/tasks/list/";
    };
    const convertToUTC = (date, time) => {
      const localDateTime = new Date(`${date}T${time}:00`);
      const utcDateTime = new Date(localDateTime).toISOString();
      return utcDateTime;
  };
    const createNote = (e) => {
      const utcDateTime = convertToUTC(format(new Date(value), 'yyyy-MM-dd'), `${hhTime}:${mmTime}`); 
      if (textNote) {
        api.post(`${apiUrl}api/v1/notes/`, {
            'text': textNote,
            'date_time_send_notification': utcDateTime,
            'link': linkNote,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 201) {
              updateNotes();
            }
        }).catch(error => {
            const status = error.response ? error.response.status : null;
            if (error.response.status === 400) {
              if (error.response.data.link) {
                alert(`Код ошибки: ${status}, некорректная ссылка.`, error);
              }
            }
        });
      }
      else {
        alert("Введите заметку");
      }
    };
    const deleteNote = (e, n) => {
      if (window.confirm('Удалить заметку?')) {
        api.delete(`${apiUrl}api/v1/notes/${n.id}/`, {
          headers: {
            'Authorization': 'Bearer ' + token
          },
        }).then((response) => {
            if (response.status === 204) {
              updateNotes();
            }
        }).catch(error => {
          console.error(error);
        });
      }
      else {}
    };
    const handleGoToTaskNewPin = (e) => {
      if (e.button === 1) {
        e.preventDefault();
        window.open("/tasks/list/");
      }
    };

    function getnumbertask() {
        const split = (window.location.pathname).split('/');
        const task_pk = split[2];
        return (<label id="boardname">Задача {task_pk}</label>);
    };

    const handleGoToSettings = (event) => {
      window.location.href = "/settings";
    };
    const handleGoToSettingsNewPin = (e) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open("/settings");
        }
    };
  
    function CheckAuth() {
        if (user !== '') {
            if (location.pathname === '/')
            {
              return(<button onClick={() => window.location.href = '/main'} id="redirectProfile">{user.formated_fio ? user.formated_fio: user.username}</button>);
            }
            else {
              return(<button onMouseDown={(e) => handleGoToProfileNewPin(e)} onClick={handleGoToProfile} id="redirectProfile">{user.formated_fio ? user.formated_fio: user.username}</button>);
            }
        }
      else {
        return(
            <div id="div-with-auth-and-reg-button">
                <button onClick={handleGoToAuth} id='lol'>Войти</button>
                <button onClick={handleGoToRegist} id='lol' >Зарегистрироваться</button>
            </div>
        );
      }
    };

    const handleGoToTechnicalSupport = (event) => {
      window.location.href = "/main-info";
    };
    const handleGoToTechnicalSupportNewPin = (e) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open("/main-info");
        }
    };

    React.useEffect(() => {
      const CheckPermission = async () => {
        await api.get(`${apiUrl}api/v1/user/permissions/`, {
          headers: {
              'Authorization': 'Bearer ' + token
          },
        }).then((response) => {
          if (response.status === 200) {
              if (location.pathname.startsWith('/create-task')){
                if (response.data.includes('owner_organization') || response.data.includes('Can add Задача')) {
                }
                else{
                  window.location.href = '/main';
                }
              }
              if (location.pathname.startsWith('/settings')){
                if (response.data.includes('owner_organization') || response.data.includes('Can view group')
                  || (response.data.includes('Can add group') && response.data.includes('Can view permission'))
                  ) {}
                else{
                  window.location.href = '/main';
                }
              }
              if (response.data.includes('owner_organization')){
                setCheckRole(true);
              }
              if (response.data.includes('Can view permission')){
                setCheckPermission(true);
              }
              if (response.data.includes('Can add group')){
                setAddRole(true);
              }
          }
      }).catch(error => {
          console.log(error);
      });
      }
          api.get(`${apiUrl}api/identity/auth/users/me/`, {
              headers: {
                  'Authorization': 'Bearer ' + token
              },
          }).then((response) => {
            if (response.status === 200) {
              setUser(response.data);
              if (response.data.username === author) {
                localStorage.setItem('author', author);
              }
              else {
                localStorage.setItem('author', '');
              }
              localStorage.setItem('username', response.data.username);
              localStorage.setItem('can_changed_task', response.data.id)
            }
          }).catch(error =>{
            console.error(error);
          });
        // if (location.pathname.startsWith('/tasks/')) {
          // api.get(`${apiUrl}api/v1/tasks/`, {
          //     headers: {
          //         'Authorization': 'Bearer ' + token
          //     },
          // }).then((response) => {
          //   if (response.status === 200) {
          //     const task = response.data;
          //     setTasksnumber(task.length);
          //   }
          // }).catch(error =>{
          //     if (error.response.status === 403){
          //        window.location.href = '/main';
          //   }
          // }
    // );
      // }
    CheckPermission();
    // eslint-disable-next-line
  }, []);

  if (location.pathname==='/login' || location.pathname==='/' 
  || location.pathname==='' || location.pathname==='/create-appeal' 
  || location.pathname==='/about' || location.pathname==='/privacy-policy'
  || location.pathname==='/developers-info/' || location.pathname==='/developers-info'
  || location.pathname==='/create-appeal/' 
  || location.pathname==='/about/' || location.pathname==='/privacy-policy/'
  || location.pathname==='/prices-info' || location.pathname==='/prices-info/'
  || location.pathname==='/solutions-info' || location.pathname==='/solutions-info/'
  || location.pathname==='/registration' || location.pathname==='/registration/'
  || location.pathname.startsWith('/invite-organization/')) {
    return (
      <header className="header">
            <div id="companyName">
              <Link onClick={() => window.location.href= '/main'} id="project-name">
                <img id="logo-tz" src={apiUrlFrontend + 'logo-light.svg'} alt=''/>
              </Link>
            </div>
            {CheckAuth()}
            <button style={{marginLeft: 'auto'}} id="open-modal-phone" onClick={()=>setModalIsOpenPhone(true)}>
              <img id="btn-modal-open" src={apiUrlFrontend + '/burger-btn.svg'} alt="" />
            </button>
            <Modal
              className="ModalPhone"
              isOpen={modalIsOpenPhone}
              onRequestClose={closeModal}
              overlayClassName="Overlay"
            >
                <div id="div-modal-phone">
                  <div id="div-for-close-and-buttons-modal">
                    <div id="div-all-btn-modal">
                      { location.pathname==='/developers-info' || location.pathname==='/developers-info/' ? 
                      <Link style={{color: "white", fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/developers-info'} id="developers">Разработчики</Link>: 
                      <Link onClick={() => window.location.href= '/developers-info'} id="developers">Разработчики</Link>
                      }
                      { location.pathname==='/solutions-info' || location.pathname==='/solutions-info/' ?
                      <Link style={{color: "white", fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/solutions-info'} id="solutions">Решения</Link>: 
                      <Link onClick={() => window.location.href= '/solutions-info'} id="solutions">Решения</Link>
                      }
                      { location.pathname==='/prices-info' || location.pathname==='/prices-info/' ?
                      <Link style={{color: "white", fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/prices-info'} id="prices">Цены</Link>: 
                      <Link onClick={() =>  window.location.href= '/prices-info'} id="prices">Цены</Link>
                      }
                    </div>
                    <button id="close-modal-checklist" onClick={closeModal}>
                      <img id="btn-modal-close" src={apiUrlFrontend + '/close-btn.svg'} alt=""></img>
                    </button>
                  </div>
                </div>
              </Modal>
      </header>
    );
  } 
  else if (location.pathname==='/main' ||
     location.pathname.startsWith('/profile/') || 
     location.pathname==='/create-task' || 
     location.pathname==='/settings'
     || location.pathname==='/main-info' || location.pathname==='/main-info/') {
    return(
    <header className="header">
          <div id="companyName">
            <Link onClick={() => window.location.href= '/main'} id="project-name">
              <img id="logo-tz" src={apiUrlFrontend + 'logo-light.svg'} alt=''/>
            </Link>
          </div>
          {user ? user.available_organization.length > 1 ?
          <button id="redirectProfile"
            onClick={() => (setIsOpenChangeorganization(!modalIsOpenChangeOrganization))}
          >
            Сменить организацию
          </button>: null: null}
          <Modal
            className="Modal"
            isOpen={modalIsOpenChangeOrganization}
            onRequestClose={closeModal}
            overlayClassName="Overlay"
          >  
            <div id="div-modal-phone">
              <ChoiceOrganization/>
            </div>
          </Modal>
          <Modal
            className="Modal"
            isOpen={modalIsOpenNotes}
            onRequestClose={closeModal}
            overlayClassName="Overlay"
          >  
            <Modal
                className="Modal-calendar"
                isOpen={modalIsOpenCalendar}
                onRequestClose={closeModalCalendar}
                overlayClassName="Overlay"
            >   
                <div id="div-calendar">        
                    <Calendar  onChange={(date) => {
                        handleChangeCalendar(date);
                    }}  value={value}/>
                </div>
            </Modal>
            <div id="div-modal-phone">
              <div id="div-create-note">
                <textarea id="auto-resizing-textarea"
                  ref={textareaRef}
                  onInput={handleInput}
                  onChange={e => setTextNote(e.target.value)}
                  placeholder="Введите текст..."
                />
              </div>
              <input
                  id="input-note-link"
                  type="url"
                  placeholder="Вставьте ссылку"
                  value={linkNote}
                  onChange={handleChangelinkNote}
              />
                <div style={{display: "flex"}}>
                  <button id="btn-create-note" onClick={() => setIsOpenCalendar(!modalIsOpenCalendar)}>
                    { format(new Date(value), 'yyyy-MM-dd')}
                  </button>
                <select id="select-time-hh" value={hhTime} onChange={handleChangeHHTime}>
                  {[...Array(24).keys()].map((m) => (
                    <option key={m} value={m < 10 ? `0${m}` : m}>
                        {m < 10 ? `0${m}` : m}
                    </option>
                  ))}
                </select>
                <label id="label-time-hh-mm">:</label>
                <select id="select-time-mm" value={mmTime} onChange={handleChangeMMTime}>
                  {[...Array(60).keys()].map((m) => (
                    <option key={m} value={m < 10 ? `0${m}` : m}>
                        {m < 10 ? `0${m}` : m}
                    </option>
                  ))}
                </select>
                  <button id="btn-create-note" onClick={(e) => createNote(e)}>
                    Создать
                  </button>
                </div>
                <label id="hint-for-create-note">
                    Вы можете выбрать дату и время отправки напоминания, а так же указать свою ссылку.
                    Напоминание в мессенджеры и на почту придет как обычно, 
                    если требуется отправка непосредственно в браузер, разрешите уведомления.
                  </label>
              <div id="div-for-choice-organization">
                {notes.map((n, nindex) => 
                <div>
                  <div id="div-with-link-and-datetime-note">
                    <label id="note-date-time">
                      {format(new Date(n.date_time_send_notification), 'dd.MM.yyyy hh:mm')}
                    </label>
                    {n.link ?
                    <a id="note-link" href={n.link} target="_blank" rel="noopener noreferrer">
                      {n.link.slice(0, 25)}...
                    </a>: null}
                  </div>
                  <div style={{display: "flex"}}>
                  <div id="div-text-note" key={nindex}>
                    {n.text}
                  </div>
                  <button id="btn-delete-note" onClick={(e) => deleteNote(e, n)}>
                    X
                  </button>
                  </div>
                </div> 
                )}
              </div>
            </div>
          </Modal>
          <div className="divbtn">
            <button onMouseDown={(e) => handleGoToBoardsNewPin(e)} onClick={handleGoToBoards} id="openActiveBoards">Активные доски
            </button>
              <button onMouseDown={(e) => handleGoToTaskNewPin(e)} onClick={handleGoToTask} id="openActiveTasks">Задачи</button>
              <button onClick={handleOpenNotes} id="openActiveBoards">Заметки
              </button>
              {checkRole === true || (checkPermission === true && addRole === true) ?
                    <button
                        onMouseDown={(e) => handleGoToSettingsNewPin(e)}
                        onClick={(event) => handleGoToSettings(event)} 
                        id="openActiveBoards"
                    >
                        Настройки</button>
                : null
              }
              <button id="go-to-main-info-button" onMouseDown={(e) => handleGoToTechnicalSupportNewPin(e)}
               onClick={(event) => handleGoToTechnicalSupport(event)}>Обзор задач</button>
            {CheckAuth()}
          </div>
    </header>
    );
  }
  else if(location.pathname.startsWith('/boards/')) {
    return(
      <header className="header">
          <div id="companyName">
            <Link onClick={() => window.location.href= '/main'} id="project-name">
             <img id="logo-tz" src={apiUrlFrontend + 'logo-light.svg'} alt=''/>
            </Link>
              <label id="boardname">{boardname}</label>
            </div>
            {user ? user.available_organization.length > 1 ?
            <button id="redirectProfile"
              onClick={() => (setIsOpenChangeorganization(!modalIsOpenChangeOrganization))}
            >
              Сменить организацию
            </button>: null: null}
            <Modal
                className="Modal"
                isOpen={modalIsOpenChangeOrganization}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
              >  
              <div id="div-modal-phone">
                <ChoiceOrganization/>
              </div>
            </Modal>
          <Modal
            className="Modal"
            isOpen={modalIsOpenNotes}
            onRequestClose={closeModal}
            overlayClassName="Overlay"
          >  
          <Modal
                className="Modal-calendar"
                isOpen={modalIsOpenCalendar}
                onRequestClose={closeModalCalendar}
                overlayClassName="Overlay"
            >   
                <div id="div-calendar">        
                    <Calendar  onChange={(date) => {
                        handleChangeCalendar(date);
                    }}  value={value}/>
                </div>
            </Modal>
            <div id="div-modal-phone">
              <div id="div-create-note">
                <textarea id="auto-resizing-textarea"
                  ref={textareaRef}
                  onInput={handleInput}
                  onChange={e => setTextNote(e.target.value)}
                  placeholder="Введите текст..."
                />
              </div>
              <input
                  id="input-note-link"
                  type="url"
                  placeholder="Вставьте ссылку"
                  value={linkNote}
                  onChange={handleChangelinkNote}
              />
                <div style={{display: "flex"}}>
                  <button id="btn-create-note" onClick={() => setIsOpenCalendar(!modalIsOpenCalendar)}>
                    { format(new Date(value), 'yyyy-MM-dd')}
                  </button>
                <select id="select-time-hh" value={hhTime} onChange={handleChangeHHTime}>
                  {[...Array(24).keys()].map((m) => (
                    <option key={m} value={m < 10 ? `0${m}` : m}>
                        {m < 10 ? `0${m}` : m}
                    </option>
                  ))}
                </select>
                <label id="label-time-hh-mm">:</label>
                <select id="select-time-mm" value={mmTime} onChange={handleChangeMMTime}>
                  {[...Array(60).keys()].map((m) => (
                    <option key={m} value={m < 10 ? `0${m}` : m}>
                        {m < 10 ? `0${m}` : m}
                    </option>
                  ))}
                </select>
                  <button id="btn-create-note" onClick={(e) => createNote(e)}>
                    Создать
                  </button>
                </div>
                <label id="hint-for-create-note">
                    Вы можете выбрать дату и время отправки напоминания, а так же указать свою ссылку.
                    Напоминание в мессенджеры и на почту придет как обычно, 
                    если требуется отправка непосредственно в браузер, разрешите уведомления.
                  </label>
              <div id="div-for-choice-organization">
                {notes.map((n, nindex) => 
                <div>
                  <div id="div-with-link-and-datetime-note">
                    <label id="note-date-time">
                      {format(new Date(n.date_time_send_notification), 'dd.MM.yyyy hh:mm')}
                    </label>
                    {n.link ?
                    <a id="note-link" href={n.link} target="_blank" rel="noopener noreferrer">
                      {n.link.slice(0, 25)}...
                    </a>: null}
                  </div>
                  <div style={{display: "flex"}}>
                  <div id="div-text-note" key={nindex}>
                    {n.text}
                  </div>
                  <button id="btn-delete-note" onClick={(e) => deleteNote(e, n)}>
                    X
                  </button>
                  </div>
                </div> 
                )}
              </div>
            </div>
          </Modal>
            <div className="divbtn">
              <button onClick={handleGoToBoards} onMouseDown={(e) => handleGoToBoardsNewPin(e)} id="openActiveBoards">Активные доски
              </button>
              <button onMouseDown={(e) => handleGoToTaskNewPin(e)} onClick={handleGoToTask} id="openActiveTasks">Задачи
              </button>
              <button onClick={handleOpenNotes} id="openActiveBoards">Заметки
              </button>
              {checkRole === true || (checkPermission === true && addRole === true) ?
                    <button 
                        onMouseDown={(e) => handleGoToSettingsNewPin(e)}
                        onClick={(event) => handleGoToSettings(event)} 
                        id="openActiveBoards"
                    >
                        Настройки</button>
                : null
              }
              <button id="go-to-main-info-button" onMouseDown={(e) => handleGoToTechnicalSupportNewPin(e)}
              onClick={(event) => handleGoToTechnicalSupport(event)}>Обзор задач</button>
              {CheckAuth()}
            </div>
      </header>
      );
  }
  else if(location.pathname === '/tasks/list/') {
    return(
      <header className="header">
          <div id="companyName">
            <Link onClick={() => window.location.href= '/main'} id="project-name">
              <img id="logo-tz" src={apiUrlFrontend + 'logo-light.svg'} alt=''/>
            </Link>
              {/* <label id="boardname"></label> */}
          </div>
          {user ? user.available_organization.length > 1 ?
          <button id="redirectProfile"
            onClick={() => (setIsOpenChangeorganization(!modalIsOpenChangeOrganization))}
          >
            Сменить организацию
          </button>: null: null}
          <Modal
              className="Modal"
              isOpen={modalIsOpenChangeOrganization}
              onRequestClose={closeModal}
              overlayClassName="Overlay"
            >  
            <div id="div-modal-phone">
              <ChoiceOrganization/>
            </div>
          </Modal>
          <Modal
            className="Modal"
            isOpen={modalIsOpenNotes}
            onRequestClose={closeModal}
            overlayClassName="Overlay"
          >  
          <Modal
                className="Modal-calendar"
                isOpen={modalIsOpenCalendar}
                onRequestClose={closeModalCalendar}
                overlayClassName="Overlay"
            >   
                <div id="div-calendar">        
                    <Calendar  onChange={(date) => {
                        handleChangeCalendar(date);
                    }}  value={value}/>
                </div>
            </Modal>
            <div id="div-modal-phone">
              <div id="div-create-note">
                <textarea id="auto-resizing-textarea"
                  ref={textareaRef}
                  onInput={handleInput}
                  onChange={e => setTextNote(e.target.value)}
                  placeholder="Введите текст..."
                />
              </div>
              <input
                  id="input-note-link"
                  type="url"
                  placeholder="Вставьте ссылку"
                  value={linkNote}
                  onChange={handleChangelinkNote}
              />
                <div style={{display: "flex"}}>
                  <button id="btn-create-note" onClick={() => setIsOpenCalendar(!modalIsOpenCalendar)}>
                    { format(new Date(value), 'yyyy-MM-dd')}
                  </button>
                <select id="select-time-hh" value={hhTime} onChange={handleChangeHHTime}>
                  {[...Array(24).keys()].map((m) => (
                    <option key={m} value={m < 10 ? `0${m}` : m}>
                        {m < 10 ? `0${m}` : m}
                    </option>
                  ))}
                </select>
                <label id="label-time-hh-mm">:</label>
                <select id="select-time-mm" value={mmTime} onChange={handleChangeMMTime}>
                  {[...Array(60).keys()].map((m) => (
                    <option key={m} value={m < 10 ? `0${m}` : m}>
                        {m < 10 ? `0${m}` : m}
                    </option>
                  ))}
                </select>
                  <button id="btn-create-note" onClick={(e) => createNote(e)}>
                    Создать
                  </button>
                </div>
                <label id="hint-for-create-note">
                    Вы можете выбрать дату и время отправки напоминания, а так же указать свою ссылку.
                    Напоминание в мессенджеры и на почту придет как обычно, 
                    если требуется отправка непосредственно в браузер, разрешите уведомления.
                  </label>
              <div id="div-for-choice-organization">
                {notes.map((n, nindex) => 
                <div>
                  <div id="div-with-link-and-datetime-note">
                    <label id="note-date-time">
                      {format(new Date(n.date_time_send_notification), 'dd.MM.yyyy hh:mm')}
                    </label>
                    {n.link ?
                    <a id="note-link" href={n.link} target="_blank" rel="noopener noreferrer">
                      {n.link.slice(0, 25)}...
                    </a>: null}
                  </div>
                  <div style={{display: "flex"}}>
                  <div id="div-text-note" key={nindex}>
                    {n.text}
                  </div>
                  <button id="btn-delete-note" onClick={(e) => deleteNote(e, n)}>
                    X
                  </button>
                  </div>
                </div> 
                )}
              </div>
            </div>
          </Modal>
          <div className="divbtn">
            <button onClick={handleGoToBoards} onMouseDown={(e) => handleGoToBoardsNewPin(e)} id="openActiveBoards">Активные доски
            </button>
            <button onMouseDown={(e) => handleGoToTaskNewPin(e)} onClick={handleGoToTask} id="openActiveTasks">Задачи
            </button>
            <button onClick={handleOpenNotes} id="openActiveBoards">Заметки
            </button>
            {checkRole === true || (checkPermission === true && addRole === true) ?
                    <button 
                        onMouseDown={(e) => handleGoToSettingsNewPin(e)}
                        onClick={(event) => handleGoToSettings(event)} 
                        id="openActiveBoards"
                    >
                        Настройки</button>
                : null
            }
            <button id="go-to-main-info-button" onMouseDown={(e) => handleGoToTechnicalSupportNewPin(e)} onClick={(event) => handleGoToTechnicalSupport(event)}>Обзор задач</button>
            {CheckAuth()}
          </div>
      </header>
    );
  }
  else if(location.pathname.startsWith('/task/')) {
    return(
      <header className="header">
            <div id="companyName">
            <Link onClick={() => window.location.href= '/main'} id="project-name">
              <img id="logo-tz" src={apiUrlFrontend + 'logo-light.svg'} alt=''/>
            </Link>
              {getnumbertask()}
            </div>
            {user ? user.available_organization.length > 1 ?
            <button id="redirectProfile"
              onClick={() => (setIsOpenChangeorganization(!modalIsOpenChangeOrganization))}
            >
              Сменить организацию
            </button>: null: null}
            <Modal
                className="Modal"
                isOpen={modalIsOpenChangeOrganization}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
              >  
              <div id="div-modal-phone">
                <ChoiceOrganization/>
              </div>
            </Modal>
          <Modal
            className="Modal"
            isOpen={modalIsOpenNotes}
            onRequestClose={closeModal}
            overlayClassName="Overlay"
          >  
          <Modal
                className="Modal-calendar"
                isOpen={modalIsOpenCalendar}
                onRequestClose={closeModalCalendar}
                overlayClassName="Overlay"
            >   
                <div id="div-calendar">        
                    <Calendar  onChange={(date) => {
                        handleChangeCalendar(date);
                    }}  value={value}/>
                </div>
            </Modal>
            <div id="div-modal-phone">
              <div id="div-create-note">
                <textarea id="auto-resizing-textarea"
                  ref={textareaRef}
                  onInput={handleInput}
                  onChange={e => setTextNote(e.target.value)}
                  placeholder="Введите текст..."
                />
              </div>
              <input
                  id="input-note-link"
                  type="url"
                  placeholder="Вставьте ссылку"
                  value={linkNote}
                  onChange={handleChangelinkNote}
              />
                <div style={{display: "flex"}}>
                  <button id="btn-create-note" onClick={() => setIsOpenCalendar(!modalIsOpenCalendar)}>
                    { format(new Date(value), 'yyyy-MM-dd')}
                  </button>
                <select id="select-time-hh" value={hhTime} onChange={handleChangeHHTime}>
                  {[...Array(24).keys()].map((m) => (
                    <option key={m} value={m < 10 ? `0${m}` : m}>
                        {m < 10 ? `0${m}` : m}
                    </option>
                  ))}
                </select>
                <label id="label-time-hh-mm">:</label>
                <select id="select-time-mm" value={mmTime} onChange={handleChangeMMTime}>
                  {[...Array(60).keys()].map((m) => (
                    <option key={m} value={m < 10 ? `0${m}` : m}>
                        {m < 10 ? `0${m}` : m}
                    </option>
                  ))}
                </select>
                  <button id="btn-create-note" onClick={(e) => createNote(e)}>
                    Создать
                  </button>
                </div>
                <label id="hint-for-create-note">
                    Вы можете выбрать дату и время отправки напоминания, а так же указать свою ссылку.
                    Напоминание в мессенджеры и на почту придет как обычно, 
                    если требуется отправка непосредственно в браузер, разрешите уведомления.
                  </label>
              <div id="div-for-choice-organization">
                {notes.map((n, nindex) => 
                <div>
                  <div id="div-with-link-and-datetime-note">
                    <label id="note-date-time">
                      {format(new Date(n.date_time_send_notification), 'dd.MM.yyyy hh:mm')}
                    </label>
                    {n.link ?
                    <a id="note-link" href={n.link} target="_blank" rel="noopener noreferrer">
                      {n.link.slice(0, 25)}...
                    </a>: null}
                  </div>
                  <div style={{display: "flex"}}>
                  <div id="div-text-note" key={nindex}>
                    {n.text}
                  </div>
                  <button id="btn-delete-note" onClick={(e) => deleteNote(e, n)}>
                    X
                  </button>
                  </div>
                </div> 
                )}
              </div>
            </div>
          </Modal>
            <div className="divbtn">
            <button onClick={handleGoToBoards} onMouseDown={(e) => handleGoToBoardsNewPin(e)} id="openActiveBoards">Активные доски
            </button>
              <button onMouseDown={(e) => handleGoToTaskNewPin(e)} onClick={handleGoToTask} id="openActiveTasks">Задачи
              </button>
              <button onClick={handleOpenNotes} id="openActiveBoards">Заметки
              </button>
              {checkRole === true || (checkPermission === true && addRole === true) ?
                    <button 
                        onMouseDown={(e) => handleGoToSettingsNewPin(e)}
                        onClick={(event) => handleGoToSettings(event)} 
                        id="openActiveBoards"
                    >
                        Настройки</button>
                : null
              }
              <button id="go-to-main-info-button" onMouseDown={(e) => handleGoToTechnicalSupportNewPin(e)} onClick={(event) => handleGoToTechnicalSupport(event)}>Обзор задач</button>
              <button onClick={handleGoToRevertBoard} onMouseDown={(e) => handleGoToRevertBoardNewPin(e)} id="openActiveTasks">Вернуться на доску
              </button>
              {CheckAuth()}
            </div>
      </header>
    );
  }
  else if (location.pathname.startsWith('/change-password/')) {
    return(
    <header className="header">
    <div id="companyName">
      <Link onClick={() => window.location.href= '/main'} id="project-name">
        <img id="logo-tz" src={apiUrlFrontend + 'logo-light.svg'} alt=''/>
      </Link>
    </div>
    <div id="div-with-circle">
      { location.pathname==='/developers-info' || location.pathname==='/developers-info/' ? 
        <Link style={{fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/developers-info'} id="developers">Разработчики</Link>: 
        <Link onClick={() => window.location.href= '/developers-info'} id="developers">Разработчики</Link>
      }
      <div className="circle"></div>
      { location.pathname==='/solutions-info' || location.pathname==='/solutions-info/' ?
        <Link style={{fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/solutions-info'} id="solutions">Решения</Link>: 
        <Link onClick={() => window.location.href= '/solutions-info'} id="solutions">Решения</Link>
      } 
      <div className="circle"></div>
      { location.pathname==='/prices-info' || location.pathname==='/prices-info/' ?
        <Link style={{fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/prices-info'} id="prices">Цены</Link>: 
        <Link onClick={() => window.location.href= '/prices-info'} id="prices">Цены</Link>
      }
    </div>
</header>
    )
  }
  else if (location.pathname.startsWith('/choice-organization')){
    return (
      <header className="header">
      </header>
    )
  }
  else {
    return(
      <h1>Такой страницы не существует</h1>
    );
  }
}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
export default Header;