import React, { useEffect, useState } from 'react';
import './styles/InfoMain.css';
import api from '/app/frontend/src/api';
import { format } from 'date-fns';
import Modal from 'react-modal';
import Calendar from 'react-calendar';
import { Tooltip } from 'react-tooltip';
import { getOnlineUser } from './components/CustomContextToHeader';

const InfoMain = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const [flameTask, setFlameTask] = useState([]);
    const [openTask, setOpenTask] = useState([]);
    const [inProgressTask, setInProgressTask] = useState([]);
    const [endDeadlineTask, setEndDeadlineTask] = useState([]);
    const [isOpenOpenTask, setIsOpenOpenTask] = useState(true);
    const [isOpenInProgressTask, setIsOpenInProgressTask] = useState(true);
    const [isOpenEndDeadlineTask, setIsOpenEndDeadlineTask] = useState(true);
    const [isOpenTaskCompletedStatistics, setIsOpenTaskCompletedStatistics] = useState(false);
    const [isOpenTaskInProgressStatistics, setIsOpenTaskInProgressStatistics] = useState(false);
    const [isOpenTaskStatistics, setIsOpenTaskStatistics] = useState(false);
    const [modalIsOpenCalendar, setIsOpenCalendar] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [statistics, setStatistics] = useState([]);
    const [detailStatistics, setDetailStatistics] = useState([]);
    const [isOpenModalDetailStatisticUser, setIsOpenModalDetailStatisticUser] = useState(false);
    const [isOpenDetailUserCompletedTask, setIsOpenDetailUserCompletedTask] = useState(false);
    const [isOpenDetailUserOpenTask, setIsOpenDetailUserOpenTask] = useState(false);
    const [isOpenDetailUserInProgressTask, setIsOpenDetailUserInProgressTask] = useState(false);
    const [periodCompleteTask, setPeriodCompleteTask] = useState(false);
    const [dateUserCountTask, setDateuserCountTask] = useState([]);
    const [canViewTask, setCanViewTask] = useState(false);
    const [canViewUser, setCanViewUser] = useState(false);
    const [ownerOrganization, setOwnerOrganization] = useState(false);
    const [isOpenModalDetailTaskUserSelectedDate, setIsOpenModalDetailTaskUserSelectedDate] = useState(false);
    const [detailTaskuserSelectedDate, setDetailTaskuserSelectedDate] = useState([]);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchFilteredData = async () => {
            await api.post(`${apiUrl}api/v1/tasks/get-filtered-task/`, { 
                flametask: "1"
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setFlameTask(response.data);
                }
            }).catch(error =>{
                console.error(error);
            });
            await api.post(`${apiUrl}api/v1/tasks/get-filtered-task/`, { 
                status: "IsOpen"
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setOpenTask(response.data);
                }
            }).catch(error =>{
                console.error(error);
            });
            await api.post(`${apiUrl}api/v1/tasks/get-filtered-task/`, { 
                status: "InProgress"
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setInProgressTask(response.data);
                }
            }).catch(error =>{
                console.error(error);
            });
            await api.post(`${apiUrl}api/v1/tasks/get-filtered-task/`, { 
                overdue: "1"
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setEndDeadlineTask(response.data);
                }
            }).catch(error =>{
                console.error(error);
            });
            await api.get(`${apiUrl}api/v1/get-statistics/`, { 
                params: {
                    'IsOpen': 1,
                    'InProgress': 1,
                    'Completed': 1,
                    'countinprogresstask': 1,
                    'countisopentask': 1,
                    'countcompletedtask': 1,
                },
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setStatistics(response.data);
                }
            }).catch(error =>{
                console.error(error);
            });
        }
        fetchFilteredData();
        const loadBoards = async () => {
            await api.get(`${apiUrl}api/v1/user/permissions/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    if (response.data.includes('Can view Задача')) {
                        setCanViewTask(true);
                    }
                    if (response.data.includes('Can view Пользователь')) {
                        setCanViewUser(true);
                    }
                    if (response.data.includes('owner_organization')) {
                        setCanViewTask(true);
                        setCanViewUser(true);
                        setOwnerOrganization(true);
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }
        loadBoards();
    // eslint-disable-next-line
    }, []);

    const handleDateChange = date => {
        if (dateRange[0] && dateRange[1]) {
            setDateRange([date, null]);
        } 
        else if (dateRange[0] == null) {
            setDateRange([date, null]);
        } 
        else {
            setDateRange(prevRange => [prevRange[0], date]);
            const currentformatdt0 = format(new Date(dateRange[0]), 'yyyy-MM-dd');
            const currentformatdt1 = format(new Date(date), 'yyyy-MM-dd');
            getUserPeriodStatistics(currentformatdt0, currentformatdt1);
            setIsOpenCalendar(false);
            setDateRange([null, null]);
        }
    };

    const getUserPeriodStatistics = (date_start, date_end) => {
        api.get(`${apiUrl}api/v1/get-statistics/user-detail-period/`, { 
            params: {
                'date_start': date_start,
                'date_end': date_end,
                'completed': 1,
            },
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setDateuserCountTask(response.data.user_task);
                setPeriodCompleteTask(true);
            }
        }).catch(error =>{
            console.error(error);
        });
    };

    const handleGoToFlameTask = (e, task_id) => {
        window.location.href = `/task/${task_id}`;
    };

    document.title = "Обзор задач - ТвояЗадача";

    const handleOpenModalDetailStatisticTask = (event) => {
        event.preventDefault();
        api.get(`${apiUrl}api/v1/get-statistics/user-detail/`, { 
            params: {
                'IsOpen': 1,
                'InProgress': 1,
                'Completed': 1,
            },
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setDetailStatistics(response.data.users);
                setIsOpenModalDetailStatisticUser(true);
            }
        }).catch(error =>{
            console.error(error);
        });
    };

    const handleopenModalCalendar = () => {
        // closeModal();
        setIsOpenCalendar(true);
    };

    const handleOpenModalDetailTaskUserSelectedDate = (tasks) => {
        setDetailTaskuserSelectedDate(tasks);
        setIsOpenModalDetailTaskUserSelectedDate(!isOpenModalDetailTaskUserSelectedDate);
    };

    const handleGoToFlameTaskNewPin = (e, task_id) => {
        if (e.button === 1) {
          e.preventDefault();
          window.open(`/task/${task_id}`);
        }
    };
    const users = dateUserCountTask.map(user => user.username);
    const dates = Array.from(new Set(dateUserCountTask.flatMap(user => Object.keys(user.date))))
    .sort((a, b) => new Date(a.split('.').reverse().join('-')) - new Date(b.split('.').reverse().join('-')));
    function closeModal() {
        setIsOpenCalendar(false);
        setIsOpenModalDetailStatisticUser(false);
    };

    return (
        <div className="login-page" style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", overflow: "auto"}}>
            <Modal className="ModalDetailUserStatistic"
                isOpen={isOpenModalDetailStatisticUser}
                onRequestClose={closeModal}
                overlayClassName="Overlayforcreategroup"
            >
                <div id="div-for-detail-statistic-user">
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <label id="label-detail-statistic-user">
                            Детальная статистика по сотрудникам
                        </label>
                        <button id="close-modal-detail-statistic-task" onClick={closeModal}>
                            <img src={apiUrlFrontend + "close-btn.svg"} alt=""/>
                        </button>
                    </div>
                    {ownerOrganization ?
                        <div id="filter-for-detail-user-task">
                            <button id="open-calendar-detail-user-task"
                                onClick={handleopenModalCalendar}
                            >
                                Период выполнения задач
                            </button>
                            {periodCompleteTask ?
                                <button id="close-period-statistics"
                                    onClick={() => setPeriodCompleteTask(!periodCompleteTask)}
                                >
                                    X
                                </button>: null
                            }
                        </div>: null
                    }
                    {modalIsOpenCalendar ?
                        <div style={{ position: "absolute",
                                      background: "none",
                                      borderRadius: "10px", zIndex: "2",
                                      maxWidth: "250px"
                                    }}>
                            <Calendar
                                value={dateRange}
                                onChange={handleDateChange}
                            />
                        </div>: null
                    }
                    <div id="div-with-detail-user-task">
                        {!periodCompleteTask ?
                            detailStatistics && detailStatistics.length > 0 ? 
                                (detailStatistics.map((item, index) => (
                                    <div id="div-with-user-task" key={index}>
                                        <div style={{display: "flex", gap: "10px", marginTop: "10px"}}>
                                            {getOnlineUser(item.id)}
                                            <label id="detail-statistic-username">
                                                {item.username}
                                            </label>
                                        </div>
                                        <button id="see-task-btn"
                                            onClick={() => setIsOpenDetailUserInProgressTask(!isOpenDetailUserInProgressTask)}
                                        >
                                            <div id="">
                                                В работе: {item.in_progress_tasks.length} 
                                            </div>
                                            <div id="toggle-open-tasks">
                                                &#9660;
                                            </div>
                                        </button>
                                        {isOpenDetailUserInProgressTask ?
                                            <div id="div-with-mini-card-detail-user">
                                                {item.in_progress_tasks.map((ct, ctindex) => (
                                                    <button id="mini-card-task" key={ctindex}
                                                        onClick={(e) => handleGoToFlameTask(e, ct.id)}
                                                        onMouseDown={(e) => handleGoToFlameTaskNewPin(e, ct.id)}
                                                    >
                                                        <label id="mini-card-task-title">
                                                            {ct.title}
                                                        </label>
                                                        <div id="mini-card-div-with-deadline-and-priority">
                                                            {ct.deadline ?
                                                                <label id="mini-card-task-deadline">
                                                                    {format(new Date(ct.deadline), 'dd.MM.yyyy')}
                                                                </label>: null
                                                            }
                                                        </div>
                                                    </button>
                                                ))}
                                            </div>: null
                                        }
                                        <button id="see-task-btn"
                                            onClick={() => setIsOpenDetailUserOpenTask(!isOpenDetailUserOpenTask)}
                                        >
                                            <div id="">
                                                Открытые: {item.open_tasks.length} 
                                            </div>
                                            <div id="toggle-open-tasks">
                                                &#9660;
                                            </div>
                                        </button>
                                        {isOpenDetailUserOpenTask ?
                                            <div id="div-with-mini-card-detail-user">
                                                {item.open_tasks.map((ct, ctindex) => (
                                                    <button id="mini-card-task" key={ctindex}
                                                        onClick={(e) => handleGoToFlameTask(e, ct.id)}
                                                        onMouseDown={(e) => handleGoToFlameTaskNewPin(e, ct.id)}
                                                    >
                                                        <label id="mini-card-task-title">
                                                            {ct.title}
                                                        </label>
                                                        <div id="mini-card-div-with-deadline-and-priority">
                                                            {ct.deadline ?
                                                                <label id="mini-card-task-deadline">
                                                                    {format(new Date(ct.deadline), 'dd.MM.yyyy')}
                                                                </label>: null
                                                            }
                                                        </div>
                                                    </button>
                                                ))}
                                            </div>: null
                                        }
                                        <button id="see-task-btn"
                                            onClick={() => setIsOpenDetailUserCompletedTask(!isOpenDetailUserCompletedTask)}
                                        >
                                            <div id="">
                                                Выполненные: {item.completed_tasks.length} 
                                            </div>
                                            <div id="toggle-open-tasks">
                                                &#9660;
                                            </div>
                                        </button>
                                        {isOpenDetailUserCompletedTask ?
                                            <div id="div-with-mini-card-detail-user">
                                                {item.completed_tasks.map((ct, ctindex) => (
                                                    <button id="mini-card-task" key={ctindex}
                                                        onClick={(e) => handleGoToFlameTask(e, ct.id)}
                                                        onMouseDown={(e) => handleGoToFlameTaskNewPin(e, ct.id)}
                                                    >
                                                        <label id="mini-card-task-title">
                                                            {ct.title}
                                                        </label>
                                                        <div id="mini-card-div-with-deadline-and-priority">
                                                            {ct.deadline ?
                                                                <label id="mini-card-task-deadline">
                                                                    {format(new Date(ct.deadline), 'dd.MM.yyyy')}
                                                                </label>: null
                                                            }
                                                        </div>
                                                    </button>
                                                ))}
                                            </div>: null
                                        }
                                    </div>
                                ))): 
                            null:
                                <div id="div-with-table-user-period-statistics">
                                    <table id="table-user-period-statistics">
                                        <thead>
                                            <tr>
                                                <th id="other-period-statistic-td-th">
                                                    <label style={{fontFamily: 'Roboto'}}>Имя пользователя</label>
                                                </th>
                                                {dates.map((date, index) => (
                                                    <th id="other-period-statistic-td-th" key={index}>
                                                        <label style={{fontFamily: 'Roboto', fontSize: '14px'}}>{date}</label>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map((user, userIndex) => (
                                                <tr key={userIndex}>
                                                    <td id="user-name-left-period-statistic-table">
                                                        <label style={{fontFamily: 'Roboto'}}>{user}</label>
                                                    </td>
                                                    {dates.map((date, dateIndex) => {
                                                        const userData = dateUserCountTask.find(u => u.username === user);
                                                        const dateData = userData?.date[date];
                                                        return (
                                                            <td id="other-period-statistic-td-th" key={dateIndex}>
                                                                <button 
                                                                    onClick={() => handleOpenModalDetailTaskUserSelectedDate(dateData.tasks)}
                                                                >
                                                                    {dateData ? dateData.all_task : 0}
                                                                    {dateData && dateData.tasks && dateData.tasks.some(ts => ts.time_to_completed_task < 0.16) ? 
                                                                        <img
                                                                            data-tooltip-id="my-tooltip"
                                                                            data-tooltip-content="Некоторые задачи находились в статусе в работе слишком мало времени"
                                                                            style={{ width: "20px", height: "20px", marginLeft: "5px", verticalAlign: "middle", zIndex: '100px' }} 
                                                                            alt=""
                                                                            src={apiUrlFrontend + 'alert-task-time.svg'} 
                                                                        />
                                                                    : null}
                                                                </button>
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                        }
                    </div>
                </div>
            </Modal>
            <Modal
                className="ModalDetailUserStatistic"
                isOpen={isOpenModalDetailTaskUserSelectedDate}
                onRequestClose={closeModal}
                overlayClassName="Overlayforcreategroup"
            >
                <div id="div-with-detail-user-selected-task">
                    <div style={{display: "flex", justifyContent: 'center'}}>
                        <label id="label-detail-statistic-user">
                            Выполненные задачи
                        </label>
                        <button id="close-modal-detail-statistic-task" 
                            onClick={() => setIsOpenModalDetailTaskUserSelectedDate(!isOpenModalDetailTaskUserSelectedDate)}
                        >
                            <img id="btn-modal-close" src={apiUrlFrontend + 'close-btn.svg'} alt=""/>
                        </button>
                    </div>
                    <div id="div-with-mini-card-task">
                        {detailTaskuserSelectedDate.map((task, index) => (
                            <button id="mini-card-task" key={index}
                                onClick={(e) => handleGoToFlameTask(e, task.id)}
                                onMouseDown={(e) => handleGoToFlameTaskNewPin(e, task.id)}
                            >
                                <label id="mini-card-task-title">
                                    {task.title}
                                </label>
                                <div id="mini-card-div-with-deadline-and-priority">
                                    {task.deadline ?
                                        <label id="mini-card-task-deadline">
                                            {format(new Date(task.deadline), 'dd.MM.yyyy')}
                                        </label>: null
                                    }
                                    <label style={{marginLeft: "auto", 
                                        color: task.priority <=3 ? 'rgba(100, 120, 214, 1.00)':
                                                task.priority <=7 ? 'rgba(220, 136, 0, 1.00)':
                                                'rgba(175, 52, 52, 1.00)'}}>
                                        &#9733;
                                    </label>
                                    {task.time_to_completed_task ? task.time_to_completed_task < 0.16 ? 
                                        <img
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={"Задача была перемещена в статус готово за " + 
                                                String(task.time_to_completed_task).split('.')[0] + 
                                                "часов " + String(task.time_to_completed_task).split('.')[1] + "минут."}
                                            style={{ width: "20px", height: "20px", marginLeft: "5px", verticalAlign: "middle" , zIndex: '100px' }} 
                                            alt=""
                                            src={apiUrlFrontend + 'alert-task-time.svg'} 
                                        />: 
                                        <img
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={"Задача была выполнена за " + 
                                                String(task.time_to_completed_task).split('.')[0] + 
                                                "часов " + String(task.time_to_completed_task).split('.')[1] + "минут."}
                                            style={{ width: "20px", height: "20px", marginLeft: "5px", verticalAlign: "middle" , zIndex: '100px' }} 
                                            alt=""
                                            src={apiUrlFrontend + 'time-completed-task.svg'} 
                                        />: null
                                    }
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            </Modal>
            <div id="div-with-main-card" style={{marginBottom: 'auto', marginTop: '120px'}}>
                <div id='div-without-alert' style={{top: '0'}}>
                    <div id='div-my-tasks-and-statistics'>
                        <div id="div-with-my-tasks" style={{top: '0'}}>
                            <div id="div-with-label-and-icon">
                                <label id="label-title-card">
                                    Мои задачи
                                </label>
                            </div>
                            <div id='div-see-task-btn'>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <button id="see-task-btn"
                                        onClick={() => setIsOpenOpenTask(!isOpenOpenTask)}
                                    >
                                        <div id="">
                                            Открытые: {openTask.length}
                                        </div>
                                        <div id="toggle-open-tasks">
                                            &#9660;
                                        </div>
                                    </button>
                                    {openTask.length > 0 && isOpenOpenTask ?
                                        <div id="div-with-mini-card">
                                            {openTask.map((task, index) => (
                                                <button id="mini-card-task" key={index}
                                                    onClick={(e) => handleGoToFlameTask(e, task.task_id)}
                                                    onMouseDown={(e) => handleGoToFlameTaskNewPin(e, task.task_id)}
                                                >
                                                    <label id="mini-card-task-title">
                                                        {task.title}
                                                    </label>
                                                    <div id="mini-card-div-with-deadline-and-priority">
                                                        {task.deadline ?
                                                            <label id="mini-card-task-deadline">
                                                                {format(new Date(task.deadline), 'dd.MM.yyyy')}
                                                            </label>: null
                                                        }
                                                    </div>
                                                </button>
                                            ))}
                                        </div>: null
                                    }
                                </div>
                                <div style={{display: "flex", flexDirection: "column", marginLeft: "15px"}}>
                                    <button id="see-task-btn"
                                        onClick={() => setIsOpenInProgressTask(!isOpenInProgressTask)}
                                    >
                                        <div id="">
                                            В работе: {inProgressTask.length}
                                        </div>
                                        <div id="toggle-open-tasks">
                                            &#9660;
                                        </div>
                                    </button>
                                    {inProgressTask.length > 0 && isOpenInProgressTask ?
                                        <div id="div-with-mini-card">
                                            {inProgressTask.map((task, index) => (
                                                <button id="mini-card-task" key={index}
                                                    onClick={(e) => handleGoToFlameTask(e, task.task_id)}
                                                    onMouseDown={(e) => handleGoToFlameTaskNewPin(e, task.task_id)}
                                                >
                                                    <label id="mini-card-task-title">
                                                        {task.title}
                                                    </label>
                                                    <div id="mini-card-div-with-deadline-and-priority">
                                                        {task.deadline ?
                                                            <label id="mini-card-task-deadline">
                                                                {format(new Date(task.deadline), 'dd.MM.yyyy')}
                                                            </label>: null
                                                        }
                                                    </div>
                                                </button>
                                            ))}
                                        </div>: null
                                    }
                                </div>
                                <div style={{display: "flex", flexDirection: "column", marginLeft: "15px"}}>
                                    <button id="see-task-btn"
                                        onClick={() => setIsOpenEndDeadlineTask(!isOpenEndDeadlineTask)}
                                    >
                                        <div id="">
                                            Просроченные: {endDeadlineTask.length}
                                        </div>
                                        <div id="toggle-open-tasks">
                                            &#9660;
                                        </div>
                                    </button>
                                    {endDeadlineTask.length > 0 && isOpenEndDeadlineTask ?
                                        <div id="div-with-mini-card">
                                            {endDeadlineTask.map((task, index) => (
                                                <button id="mini-card-task" key={index}
                                                    onClick={(e) => handleGoToFlameTask(e, task.task_id)}
                                                    onMouseDown={(e) => handleGoToFlameTaskNewPin(e, task.task_id)}
                                                >
                                                    <label id="mini-card-task-title">
                                                        {task.title}
                                                    </label>
                                                    <div id="mini-card-div-with-deadline-and-priority">
                                                        {task.deadline ?
                                                            <label id="mini-card-task-deadline">
                                                                {format(new Date(task.deadline), 'dd.MM.yyyy')}
                                                            </label>: null
                                                        }  
                                                    </div>
                                                </button>
                                            ))}
                                        </div>: null
                                    }
                                </div>
                            </div>
                        </div>
                        {canViewUser ? 
                            <div id="div-with-label-statistics-user">
                                <div id="div-with-label-and-icon">
                                    <label id="label-title-card">
                                        Статистика по сотрудникам
                                    </label>
                                </div>
                                <div id="user-static-div">
                                    Лидер по выполненным задачам: {statistics.completed ? statistics.completed[0].username: null}
                                </div>
                                <button id="see-task-btn"
                                    onClick={() => {
                                        setIsOpenTaskCompletedStatistics(!isOpenTaskCompletedStatistics);
                                        setIsOpenTaskInProgressStatistics(false);
                                        setIsOpenTaskStatistics(false);
                                    }}
                                >
                                    <div id="user-static-div">
                                        Всего выполненных задач: {statistics.counttaskcompleted ? statistics.counttaskcompleted: 0}
                                    </div>
                                    <div id="toggle-open-tasks">
                                        &#9660;
                                    </div>
                                </button>
                                {isOpenTaskCompletedStatistics ?
                                    <div id="div-with-list-user">
                                        {statistics.completed.map((st, index) => (
                                            <div id="div-with-username-and-count-task">
                                                <label id="statistics-username">
                                                    {st.username}
                                                </label>
                                                <label id="count-task">
                                                    {st.completed_tasks_count}
                                                </label>
                                            </div>
                                        ))}
                                    </div>: null
                                }
                                <button id="see-task-btn"
                                    onClick={() => {
                                        setIsOpenTaskInProgressStatistics(!isOpenTaskInProgressStatistics);
                                        setIsOpenTaskStatistics(false);
                                        setIsOpenTaskCompletedStatistics(false);
                                    }}
                                >
                                    <div id="user-static-div">
                                        Всего задач в работе: {statistics.counttaskinprogress ? statistics.counttaskinprogress: 0}
                                    </div>
                                    <div id="toggle-open-tasks">
                                        &#9660;
                                    </div>
                                </button>
                                {isOpenTaskInProgressStatistics ?
                                    <div id="div-with-list-user">
                                        {statistics.inprogress.map((st, index) => (
                                            <div id="div-with-username-and-count-task">
                                                <label id="statistics-username">
                                                    {st.username}
                                                </label>
                                                <label id="count-task">
                                                    {st.in_progress_tasks_count}
                                                </label>
                                            </div>
                                        ))}
                                    </div>: null
                                }
                                <button id="see-task-btn"
                                    onClick={() => {
                                        setIsOpenTaskStatistics(!isOpenTaskStatistics)
                                        setIsOpenTaskCompletedStatistics(false);
                                        setIsOpenTaskInProgressStatistics(false);
                                    }}
                                >
                                    <div id="user-static-div">
                                        Всего открытых задач: {statistics.counttaskisopen ? statistics.counttaskisopen: 0}
                                    </div>
                                    <div id="toggle-open-tasks">
                                        &#9660;
                                    </div>
                                </button>
                                {isOpenTaskStatistics ?
                                    <div id="div-with-list-user">
                                        {statistics.isopen.map((st, index) => (
                                            <div id="div-with-username-and-count-task">
                                                <label id="statistics-username">
                                                    {st.username}
                                                </label>
                                                <label id="count-task">
                                                    {st.open_tasks_count}
                                                </label>
                                            </div>
                                        ))}
                                    </div>: null
                                }
                                {canViewTask && canViewUser ?
                                    <button id="button-open-detail-task-user"
                                        onClick={(event) => handleOpenModalDetailStatisticTask(event)}
                                    >
                                        Детальный просмотр задач сотрудников
                                    </button>: null
                                }
                            </div>: null
                        }
                    </div>
                    <div id='div-news-and-notification'>
                        <div id="div-with-news">
                            <div id="div-with-label-and-icon">
                                <label id="label-title-card">
                                    Новости
                                </label>
                            </div>
                        </div>
                        <div id="div-with-notification">
                            <div id="div-with-label-and-icon">
                                <label id="label-title-card">
                                    Уведомления
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="div-with-bottom-card" style={{maxHeight: '640px'}}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <label id="label-title-card-alert">
                            Задачи требующие внимания
                        </label>
                    </div>
                    <div id="div-with-mini-card-bottom" style={{ overflowY: 'auto', overflowX: 'hidden'}}>
                        {flameTask.map((task, index) => (
                            <button id="mini-card-task" key={index}
                                onClick={(e) => handleGoToFlameTask(e, task.task_id)}
                                onMouseDown={(e) => handleGoToFlameTaskNewPin(e, task.task_id)}
                            >
                                <label id="mini-card-task-title">
                                    {task.title}
                                </label>
                                <div id="mini-card-div-with-deadline-and-priority">
                                    {task.deadline ?
                                        <label id="mini-card-task-deadline">
                                            {format(new Date(task.deadline), 'dd.MM.yyyy')}
                                        </label>: null
                                    }
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <Tooltip 
                id="my-tooltip"
                delayShow={750}
                delayHide={100}
            />    
        </div>
    );
};

export default InfoMain;