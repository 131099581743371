import React, { useState, useCallback, useEffect, useRef} from 'react';
import './styles/CreateTask.css';
import axios from "axios";
import Modal from 'react-modal';
import Calendar from 'react-calendar';
import { useDropzone } from 'react-dropzone';
import CKeditor5 from './components/CKeditor5';
import api from '/app/frontend/src/api';
import './styles/cked.css';
import { format } from 'date-fns';
import { getOnlineUser } from './components/CustomContextToHeader';

const CreateTask = () => {
    Modal.setAppElement('#root');
    const [loading, setLoading] = useState(false);
    const timerRef = useRef(null);
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const slugboards = localStorage.getItem('slugboards');
    const grouptaskcreate = localStorage.getItem('grouptaskcreate');
    const group_system_status = localStorage.getItem('grouptasksystemstatus');
    const grouptaskidcreate = localStorage.getItem('grouptaskidcreate');
    const related_task_id = localStorage.getItem('relatedtaskid');
    const [rating, setRating] = useState(null);
    const [hover, setHover] = useState(null);
    const [totalStars] = useState(10);
    const [descriptiontask, setDescriptionTask] = useState('');
    const [titletask, setTitleTask] = useState('');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalIsOpenResponsible, setIsOpenModalResponsible] = useState(false);
    const [modalIsOpenLabel, setIsOpenLabel] = useState(false);
    const [modalIsOpenGetLabel, setIsOpenGetLabel] = useState(false);
    const [modalIsOpenCalendar, setIsOpenCalendar] = useState(false);
    const [performer, setperformer] = useState([]);
    const [label, setLabel] = useState([]);
    const [selectedlabels, setSelectedLabels] = useState([]);
    const [selectedperformers, setSelectedPerformers] = useState([]);
    const [selectedResponsibles, setSelectedResponsibles] = useState([]);
    const [namelabel, setNameLabel] = useState('');
    const [files, setFiles] = useState([]);
    const [filesinfo, setFilesinfo] = useState([]);
    const [deadline, setDeadline] = useState();
    const webcolors = [
        "#FF0000", "#FF7F50", "#FFD700", "#ADFF2F",
        "#32CD32", "#20B2AA", "#00CED1", "#4682B4",
        "#1E90FF", "#6A5ACD", "#9370DB", "#FF1493",
        "#A9A9A9", "#FFFFFF", "#000000", "#808080",
    ];
    const [selectedColor, setSelectedColor] = useState('');
    const [modalIsOpenTaskTemplate, setModalIsOpenTaskTemplate] = useState(false);
    const [templatestasks, settemplatestasks] = useState([]);
    const [filtertemplatestasks, setfiltertemplatestasks] = useState([]);
    const [value, onChangeCalendar] = useState(new Date());
    const handleUpdateTaskDeadline = (date) => {
        const formattedDate = format(new Date(date), 'yyyy-MM-dd');
        setDeadline(formattedDate);
        closeModal();
    };

    useEffect(() => {
        if (grouptaskcreate !== '') {
        } 
        else {
            window.location.href = '/main'
        }
        // eslint-disable-next-line
    }, []);

    function closeModal() {
        setIsOpen(false);
        setIsOpenLabel(false);
        setIsOpenGetLabel(false);
        setIsOpenCalendar(false);
        setModalIsOpenTaskTemplate(false);
        setIsOpenModalResponsible(false);
    };

    function openModalCalendar() {
        closeModal();
        setIsOpenCalendar(true);
    };

    function openModal() {
        closeModal();
        fetchAllUsers(1, [], false);
    };

    function openModalResponsible() {
        closeModal();
        fetchAllUsers(1, [], true);
    };

    function fetchAllUsers(page = 1, allUsers = [], responsible = false) {
        if (responsible) {
            api.get(`${apiUrl}api/identity/users/`, {
                params: { 
                    page: page,
                    board_id: slugboards,
                },
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    const fetchedUsers = response.data;
                    const updatedUsersList = allUsers.concat(fetchedUsers);
                    if (response.data.next) {
                        fetchAllUsers(page + 1, updatedUsersList);
                    } else {
                        setperformer(updatedUsersList);
                        setIsOpenModalResponsible(true);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        }
        else{
            api.get(`${apiUrl}api/identity/users/`, {
                params: { 
                    page: page,
                    board_id: slugboards,
                    group_id: grouptaskidcreate
                },
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    const fetchedUsers = response.data;
                    const updatedUsersList = allUsers.concat(fetchedUsers);
                    if (response.data.next) {
                        fetchAllUsers(page + 1, updatedUsersList);
                    } else {
                        setperformer(updatedUsersList);
                        setIsOpen(true);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    function openModalLabel() {
        closeModal();
        setIsOpenLabel(true);
    };

    function openModalGetLabel() {
        closeModal();
        getlabel();
        setIsOpenGetLabel(true);
    };

    function getlabel() {
        api.get(`${apiUrl}api/v1/labels/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setLabel(response.data);
            }
        }).catch(error =>{
            console.error(error);
            const status = error.response ? error.response.status : null;
            if (error.response.status === 403)
            {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
            }
        });
    };

    const handleCreateLabel = (event) => {
        event.preventDefault();
        if (label.some(labeli => labeli.name === namelabel)) {
            alert("Метка с таким названием уже существует");
        }
        else {
            api.post(`${apiUrl}api/v1/labels/`, {
                'name': namelabel,
                'color': selectedColor
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 201) {
                    closeModal()
                }
            }).catch(error => {
                console.error(error);
                const status = error.response ? error.response.status : null;
                if (error.response.status === 400) {
                    if (error.response.data.name) {
                            alert(`Код ошибки: ${status}, ${error.response.data.name}`, error);
                        }
                    else {
                        alert(`Код ошибки: ${status}, ${error.response.data.color}`, error);
                    }
                }
            });
        }
    };

    const handleChangeNameLabel = (event) => {
        setNameLabel(event.target.value);
    };

    const handleCheckboxChangeResponsibles = (performer) => {
        if (selectedResponsibles.includes(performer)) {
            setSelectedResponsibles(selectedResponsibles.filter((selectedPerformer) => selectedPerformer !== performer));
        } else {
            setSelectedResponsibles([...selectedResponsibles, performer]);
        }
    };

    const handleCheckboxChangePerformer = (performer) => {
        if (selectedperformers.includes(performer)) {
            setSelectedPerformers(selectedperformers.filter((selectedPerformer) => selectedPerformer !== performer));
        } else {
            setSelectedPerformers([...selectedperformers, performer]);
        }
    };

    const handleCheckboxChangeLabel = (label) => {
        if (selectedlabels.includes(label)) {
            setSelectedLabels(selectedlabels.filter((selectedLabel) => selectedLabel !== label));
        } else {
            setSelectedLabels([...selectedlabels, label]);
        }
    };

    const handleOpenProfileClick = (user) => {
        window.location.href = "/profile/" + user;
    };

    const handlecreateTask = (event) => {
        event.preventDefault();
        setLoading(true);
        if (titletask === ""){
            alert("Укажите заголовок задачи!");
        }
        else{
            const refreshToken = localStorage.getItem('refreshToken');
            axios.post(`${apiUrl}api/identity/auth/jwt/refresh/`, {
                'refresh': refreshToken
            },{
                headers: {
                    'Authorization': 'Bearer ' + refreshToken
                },
            }).then((response) => {
                const newToken = response.data.access;
                localStorage.setItem('token', response.data.access);
                if (newToken) {
                    const subtasksArray = [];
                    if (related_task_id !== '') {
                        subtasksArray.push(related_task_id);
                    }
                    const filterforperformer = performer.filter((performer) => selectedperformers.includes(performer.username));
                    const performerid = filterforperformer.map(performer => performer.id);
                    const filterforresponsible = performer.filter((performer) => selectedResponsibles.includes(performer.username));
                    const responsibleid = filterforresponsible.map(performer => performer.id);
                    api.post(`${apiUrl}api/v1/tasks/`, {
                        'title': titletask,
                        'deadline': deadline,
                        'group': grouptaskidcreate,
                        'priority': rating,
                        'description': descriptiontask,
                        'label': selectedlabels,
                        'performer': performerid,
                        'system_status': group_system_status,
                        'subtasks': subtasksArray,
                        'responsible': responsibleid,
                    },{
                        headers: {
                            'Authorization': 'Bearer ' + newToken
                        },
                    }
                    ).then((response) => {
                    if (response.status === 201) {
                        localStorage.setItem('relatedtaskid', '');
                        closeModal();
                        setSelectedLabels([]);
                        setSelectedPerformers([]);
                        setSelectedResponsibles([]);
                        setTitleTask("");
                        setDeadline("");
                        setRating("");
                        if (response.data.task_id) {
                            if (files.length !== 0) {
                                handleUpload({task_id: response.data.task_id});
                            }
                            else {
                                localStorage.setItem('grouptaskcreate', '');
                                window.location.href = `/boards/${slugboards}/`;
                            }
                        }
                        else {
                            localStorage.setItem('grouptaskcreate', '');
                            window.location.href = `/boards/${slugboards}/`;
                        }
                    }
                    }).catch(error => {
                        console.error(error);
                        const status = error.response ? error.response.status : null;
                        if (error.response.status === 403 && error.response.data.detail === 'NotAllowCreateTaskInCompletedStatus') {
                            alert(`Код ошибки: ${status}, В вашей организации запрещено создавать задачи в статусе выполнено`, error);
                        }
                    }); 
            }})
            .catch(error => {
                console.error(error);
            });
    }};

    const onDropRejected = (rejectedFiles) => {
        rejectedFiles.forEach(file => {
            if (file.errors[0].code === 'file-too-large') {
                alert('Ошибка: Файл должен быть не более 25MB');
            } else {
                alert('Произошла ошибка при загрузке файла: ' + file.errors[0].message);
            }
        });
    };

    const onDrop = useCallback((acceptedFiles) => {
        const updateFiles = [...filesinfo, ...acceptedFiles];
        setFilesinfo(updateFiles);
        setFiles(updateFiles.map((file) => file));
    }, [filesinfo]);

    const {getRootProps, getInputProps,} = useDropzone({onDrop, onDropRejected, maxSize: 26214400,});
    
    function handleUpload(data) {
        if (files) {
            const formData = new FormData();
            files.forEach((file) => {
                formData.append('file', file);
            });
            api.post(`${apiUrl}api/upload-file/`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            })
            .then(response => {
                if (response.status === 201) {
                    AddFileToTask({task_id: data.task_id, file_id: response.data.map(file => file.id)});
                }
            })
            .catch(error => {
                console.error(error);
            });
        }
        else {
            localStorage.setItem('grouptaskcreate', '');
            window.location.href = `/boards/${slugboards}/`;
        }
    };

    function AddFileToTask (data){
        api.patch(`${apiUrl}api/v1/tasks/${data.task_id}/`, {
            'file': data.file_id,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
        ).then((response) => {
        if (response.status === 200) { 
            localStorage.setItem('grouptaskcreate', '');
            window.location.href = `/boards/${slugboards}/`;
        }
        }).catch(error => {
            console.error(error);
        });
        console.log("И файлы добавились");
    };
    
    const removeFile = (file) => () => {
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
        URL.revokeObjectURL(file.preview);
        const newfilesinfo = [...filesinfo];
        newfilesinfo.splice(newfilesinfo.indexOf(file), 1);
        setFilesinfo(newfilesinfo);
    };

    const handleTitleTaskChange = (event) => {
        setTitleTask(event.target.value);
    };

    const handleEditorChange = (data) => {
        setDescriptionTask(data);
    };

    const handleOpenTemplateTask = () => {
        api.get(`${apiUrl}api/v1/template-task/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                settemplatestasks(response.data);
                setfiltertemplatestasks(response.data);
                setModalIsOpenTaskTemplate(true);
            }
        }).catch(error =>{
            console.error(error);
            const status = error.response ? error.response.status: null;
            if (error.response.status === 403)
            {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
            }
        });
    };

    const handleSearch = (searchitem) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            if (searchitem.length >= 1) {
                const regex = new RegExp(searchitem, "i");
                const filtered = filtertemplatestasks.filter((task) => regex.test(task.title));
                setfiltertemplatestasks(filtered);
            } 
            else if (searchitem.length < 1) {
                setfiltertemplatestasks(templatestasks);
            }
        }, 500);
    };

    const handleCreateTaskFromTemplate = (data) => {
        getlabel();
        fetchAllUsers();
        setTitleTask(data.title);
        setDescriptionTask(data.description);
        setRating(data.priority);
        if (data.label) {
            setSelectedLabels(data.label.split(',').map(label => parseInt(label)));
        }
        setSelectedPerformers(data.performer.split(','));
        closeModal();
    };

    document.title = "Создание задачи - ТвояЗадача";

    if (loading) {
        return (
            <div 
                style={{ overflowX: 'auto',overflowY: 'hidden', width: "100%", height: "100vh", justifyContent: "space-between", alignItems: "left", display: "flex"}} 
                className='create-task-page'
            >
                <div style={{minWidth: "100%", minHeight: "100%", justifyContent: "center", alignItems: "center", display: "flex"}}>
                    <video style={{maxHeight: "250px", maxWidth: "180px", borderRadius: "30px"}}
                        alt=""
                        src={apiUrlFrontend + 'loader.webm'}
                        type="video/webm"
                        autoPlay={1}
                        loop={1}
                        muted={1}
                    />
                </div>
            </div>
        )
    }

    return (
        <div 
            className='create-task-page'
        >
            <Modal
                className="Modal"
                isOpen={modalIsOpenTaskTemplate}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >
                <div id="div-modal-subtasks">
                    <label id="performerlabel-create-task">
                        Создать задачу
                    </label>           
                    <input
                        id='search-input-subtasks-div'
                        placeholder='Введите текст, чтобы найти шаблон задачи'
                        onChange={(event) => handleSearch(event.target.value)}
                    >
                    </input>
                    <div id="scroll-subtask" style={{backgroundColor: 'none', borderRadius: '0px'}}>
                        {filtertemplatestasks.map((tt, ttindex) => (
                            <button id="btn-with-labels-name-task"
                                onClick={() => handleCreateTaskFromTemplate(tt)}
                            >
                                {tt.title}
                            </button>
                        ))}
                    </div>
                </div>
            </Modal>
            <Modal
                className="Modal-add-performer"
                isOpen={modalIsOpenGetLabel}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >
                <div id="modal-add-performer" style={{alignSelf: "center", justifySelf: "center", alignContent: "center"}}>
                    <label id="performerlabel-create-task">
                        Метка
                    </label>
                    <div id="div-for-label-list">
                        {label.map((labeli, labelindex) => (
                            <label id="div-with-label" key={labelindex}
                                style={{ 
                                    background: selectedlabels.includes(labeli.id) ? '#78509F' : 'rgb(91, 93, 108)',
                                    color: selectedlabels.includes(labeli.id) ? 'white' : 'white',        
                                }}
                            >
                                <div style={{
                                    background: labeli.color, 
                                    height: "25px",
                                    width: "25px",
                                    borderRadius: "5px",
                                }}/>
                                <label id="name-labels"key={labelindex}>
                                    <input
                                        type="checkbox"
                                        key={labelindex}
                                        name="performers"
                                        onChange={() => handleCheckboxChangeLabel(labeli.id)}
                                        checked={selectedlabels.includes(labeli.id)}
                                    />
                                    <span style={{marginLeft: "10px"}}>
                                        {labeli.name.slice(0, 14)}
                                    </span>
                                </label>
                            </label>
                        ))}
                    </div>
                    <button id="open-modal-create-label"
                        onClick={openModalLabel}
                    >
                        Создать
                    </button>
                </div>
            </Modal>
            <Modal
                className="Modal-add-performer"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >
                <div id="modal-add-performer">
                    <label id="performerlabel-create-task">
                        Исполнитель
                    </label>
                    <div id="div-for-performer-list">
                        {performer.map((performer, index) => (
                            <label id="divupforscroll" key={index}
                                style={{ background: selectedperformers.includes(performer.username) ? '#684689' : '#474858',
                                    color: selectedperformers.includes(performer.username) ? 'white' : 'white',        
                                }}
                            >
                                {getOnlineUser(performer.id)}
                                {performer.image ? 
                                    <img id="photo-profile-for-performer"
                                        src={performer.image}
                                        alt=''
                                    />:
                                    <img id="photo-profile-for-performer"
                                        src={apiUrlFrontend + "person-square.svg"}
                                        alt=''
                                    />
                                }
                                <input
                                    type="checkbox"
                                    key={index}
                                    name="performers"
                                    onChange={() => handleCheckboxChangePerformer(performer.username)}
                                    checked={selectedperformers.includes(performer.username)}
                                />
                                <span style={{marginLeft: "10px"}}>
                                    {performer.formated_fio ?
                                        performer.formated_fio.slice(0, 15):
                                        performer.username.slice(0, 15)
                                    }
                                </span>
                            </label>
                        ))}
                    </div>
                </div>
            </Modal>
            <Modal
                className="Modal-add-performer"
                isOpen={modalIsOpenResponsible}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >
                <div id="modal-add-performer">
                    <label id="performerlabel-create-task">
                        Ответственный
                    </label>
                    <div id="div-for-performer-list">
                        {performer.map((performer, index) => (
                            <label id="divupforscroll" key={index}
                                style={{ background: selectedResponsibles.includes(performer.username) ? '#684689' : '#474858',
                                    color: selectedResponsibles.includes(performer.username) ? 'white' : 'white',        
                               }}
                            >   
                                {getOnlineUser(performer.id)}
                                {performer.image ? 
                                    <img id="photo-profile-for-performer"
                                        src={performer.image}
                                        alt=''
                                    />:
                                    <img id="photo-profile-for-performer"
                                        src={apiUrlFrontend + "person-square.svg"}
                                        alt=''
                                    />
                                }
                                    <input
                                        type="checkbox"
                                        key={index}
                                        name="performers"
                                        onChange={() => handleCheckboxChangeResponsibles(performer.username)}
                                        checked={selectedResponsibles.includes(performer.username)}
                                    />
                                <span style={{marginLeft: "10px"}}>
                                    {performer.formated_fio ?
                                        performer.formated_fio.slice(0, 15):
                                        performer.username.slice(0, 15)
                                    }
                                </span>
                            </label>
                        ))}
                    </div>
                </div>
            </Modal>
            <Modal
                className="Modal-add-performer"
                isOpen={modalIsOpenLabel}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >
                <div id="modal-add-performer">
                    <label id="performerlabel-create-task">Создание метки</label>
                    <label id="hint-new-name-label">Название</label>
                    <input id="new-name-label"
                        onChange={handleChangeNameLabel}
                    >
                    </input>
                    <label id="hint-for-color-label">Цвет</label>
                    <div style={{ width: '240px', display: 'flex', flexWrap: 'wrap', borderRadius: "10px", background: "none", justifyContent: 'center'}}>
                        {webcolors.map((color, index) => (
                            <div key={index} style={{border: selectedColor === color ? '2px Solid white': 'none', borderRadius: '15px'}}
                                onClick={() => setSelectedColor(color)}
                            >
                                <div style={{ backgroundColor: color,
                                    height: selectedColor === color ? '26px': '30px',
                                    margin: '4px', cursor: 'pointer',
                                    borderRadius: '10px',
                                    width: selectedColor === color ? '46px': '50px'
                                }}/>
                            </div>
                        ))}
                    </div>
                    <button id="create-label"
                        onClick={handleCreateLabel}
                    >
                        Создать
                    </button>
                </div>
            </Modal>
            <Modal
                className="ModalforPerformer"
                isOpen={modalIsOpenCalendar}
                onRequestClose={closeModal}
                overlayClassName="Overlayforperformer"
                id="react-calendar"
            >   
                <div id="div-calendar">          
                    <Calendar onChange={(date) => {
                        onChangeCalendar(date);
                        handleUpdateTaskDeadline(date);
                    }} 
                        value={value}
                    />
                </div>
            </Modal>
            <div id="div-for-all-create">
                <textarea
                    style={{marginBottom: '15px'}}
                    type="text"
                    id="titlefortasks"
                    placeholder='Введите заголовок задачи'
                    required
                    maxLength={250}
                    value={titletask}
                    onChange={handleTitleTaskChange}
                />
                <div id='div-for-task-create-and-some-btns'>
                    <div id="div-for-editor">
                        <CKeditor5
                            onEditorChange={(data) => handleEditorChange(data)}
                            editorDisabled={true}
                            descriptiontask={descriptiontask}
                        />
                    </div>
                    <div id='div-for-settings-create-task' style={{justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                        <div id='div-with-stat-btn' style={{display: "flex", flexDirection: "column"}}>
                            <div id="div-with-status-and-add" style={{marginBottom: '5px'}}>
                                <label id="label-div-right-title" 
                                    style={{marginRight: "auto"}}
                                >
                                    Состояние
                                </label>
                                <button id="change-task-group-create">
                                    {grouptaskcreate === null ? "" : grouptaskcreate.slice(0, 12)}
                                </button>
                            </div>
                            <div id="div-perfromer-add" style={{marginLeft: '0px'}}>
                                <label id="label-div-right-title">Исполнитель</label>
                                <button id="add-performer-to-task-create"
                                    onClick={() => openModal()}
                                >
                                    Добавить
                                </button>
                            </div>
                            <div id='div-for-list-names-performer' style={{display: "flex", maxWidth: "300px", overflowX: "scroll"}}>
                                {performer.filter(pf => selectedperformers.includes(pf.username)).map((k, kindex) => (
                                    <div id="div-with-perfromer" key={kindex}>
                                        {k.image ? 
                                            <img id="image-performer"
                                                src={k.image}
                                                alt=''
                                            />:
                                            <img id="photo-profile-for-performer"
                                                src={apiUrlFrontend + "person-square.svg"}
                                                alt=''
                                            >
                                            </img>
                                        }
                                        {k.formated_fio ?
                                            <button id="open-profile-performer"
                                                onClick={() => handleOpenProfileClick(k.username)}
                                            >
                                                {k.formated_fio.slice(0, 24)}
                                            </button>:
                                            <button id="open-profile-performer"
                                                onClick={() => handleOpenProfileClick(k.username)}
                                            >
                                                {k.username.slice(0, 24)}
                                            </button>
                                        }
                                    </div>
                                ))}
                            </div>
                            <div id="div-perfromer-add" style={{marginLeft: '0px'}}>
                                <label id="label-div-right-title">Ответственный</label>
                                <button id="add-performer-to-task-create"
                                    onClick={() => openModalResponsible()}
                                >
                                    Добавить
                                </button>
                            </div>
                            <div id='div-for-list-names-performer' style={{display: "flex", maxWidth: "300px", overflowX: "scroll"}}>
                                {performer.filter(pf => selectedResponsibles.includes(pf.username)).map((k, kindex) => (
                                    <div id="div-with-perfromer" key={kindex}>
                                        {k.image ? 
                                            <img id="image-performer"
                                                src={k.image}
                                                alt=''
                                            />:
                                            <img id="photo-profile-for-performer"
                                                src={apiUrlFrontend + "person-square.svg"}
                                                alt=''
                                            >
                                            </img>
                                        }
                                        {k.formated_fio ?
                                            <button id="open-profile-performer"
                                                onClick={() => handleOpenProfileClick(k.username)}
                                            >
                                                {k.formated_fio.slice(0, 24)}
                                            </button>:
                                            <button id="open-profile-performer"
                                                onClick={() => handleOpenProfileClick(k.username)}
                                            >
                                                {k.username.slice(0, 24)}
                                            </button>
                                        }
                                    </div>
                                ))}
                            </div>
                            <div id="div-label-add-create" style={{display: 'flex', height: '40px'}}>
                                <label id="label-div-right-title">Метка</label>
                                <button id="add-performer-to-task-create"
                                    onClick={openModalGetLabel}
                                >
                                    Добавить
                                </button>
                            </div>
                            <div style={{display: "flex", maxWidth: "300px", overflowX: "scroll"}}>
                                {label.filter((lb) => selectedlabels.includes(lb.id)).map((task, taskindex) => (
                                    <div id="div-with-perfromer" key={taskindex} style={{padding: "8px"}}>
                                        <circle style={{
                                            background: task.color,
                                            height: "25px", 
                                            width: "25px",
                                            borderRadius: "5px",
                                        }}>
                                        </circle>
                                        <label id="label-name">{task.name}</label>
                                    </div>
                                ))}
                            </div>
                            <div id="div-with-deadline" style={{marginBottom: "0px", marginLeft: "0px", marginRight: "0px"}}>
                                <label id="label-div-right-title" style={{marginTop: '5px'}}>Срок сдачи</label>
                                <button id="add-deadline-to-task-create"
                                    onClick={openModalCalendar}
                                >
                                    {deadline !== "" ? deadline : "Добавить"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div id='div-with-place-file' {...getRootProps()}>
                        <label id='label-place-file'>Файлы</label>  
                        <input id="place-file-here" {...getInputProps()}/>
                        <div id="place-file-here">
                            <label id='label-max-size'>Прикрепить</label>
                        </div>
                    </div>
                    <div id='div-for-add-file'>
                        {filesinfo.map((file) => (
                            <div id="file-info-create" key={file.name}>
                                {file.name} 
                                <button id="delete-file" onClick={removeFile(file)}>
                                    <img id="btn-del-file" 
                                        src={apiUrlFrontend + 'close-btn.svg'}
                                        alt=""
                                    />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>  
                <div id='div-priority-and-add-file'>
                    <div id="div-label-add">
                        <label id="label-div-right-title">Приоритет</label>
                    </div>
                    <div id="div-with-priority">
                        <div className="App">
                            {[...Array(totalStars)].map((_, index) => {
                                const currentRating = index + 1;
                                return (
                                    <label key={index}>
                                        <input key={currentRating}
                                            type="radio"
                                            name="rating"
                                            value={currentRating}
                                            onChange={() => setRating(currentRating)}
                                        />
                                        <div className="rating-item"
                                            style={{ color: currentRating <= (hover || rating) ? "white" : "#8A8799",
                                                    background: currentRating <= (hover || rating) ? "#684689" : "#545568"}}
                                                onMouseEnter={() => setHover(currentRating)}
                                                onMouseLeave={() => setHover(null)}
                                            >
                                                {currentRating}
                                        </div>
                                    </label>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div id='div-for-bottom-btn'>
                    <button id="confirmtask-create"
                        onClick={handlecreateTask}
                    >
                        Создать
                    </button>
                    <button id="open-modal-create-template-task"
                        onClick={handleOpenTemplateTask}
                    >
                        Создать задачу из шаблона
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CreateTask;