let slugBoard = '';
let countTask = '';

const setSlugBoard = (newSlug) => {
    slugBoard = newSlug;
};

export { slugBoard, setSlugBoard };

let onlineUser;

const setOnlineUser = (newOnlineUser) => {
    onlineUser = newOnlineUser;
};

function getOnlineUser(user) {
    if (onlineUser) {
        if (onlineUser.some(c => c.id === user)) {
            return (
                <div style={{background: "green", minWidth: "10px", minHeight:"10px", maxWidth: "10px", maxHeight: "10px", borderRadius: "100px"}}/>
            )
        }
        else {
            return (
                <div style={{background: "red", minWidth: "10px", minHeight:"10px", maxWidth: "10px", maxHeight: "10px", borderRadius: "10px"}}/>
            )
        }
    }
}

function getOnlineUserOnUsername(user) {
    if (onlineUser) {
        if (onlineUser.some(c => c.username === user)) {
            return (
                <div style={{background: "green", minWidth: "10px", minHeight:"10px", maxWidth: "10px", maxHeight: "10px", borderRadius: "100px"}}/>
            )
        }
        else {
            return (
                <div style={{background: "red", minWidth: "10px", minHeight:"10px", maxWidth: "10px", maxHeight: "10px", borderRadius: "10px"}}/>
            )
        }
    }
}

export { onlineUser, setOnlineUser, getOnlineUser, getOnlineUserOnUsername };