import React, { useEffect, useState, useRef } from 'react';
import './styles/Settings.css';
import { format } from 'date-fns';
import Modal from 'react-modal';
import api from '/app/frontend/src/api';
import CKeditor5 from './components/CKeditor5';
import { getOnlineUser } from './components/CustomContextToHeader';
// import './styles/cked.css';
// import 'ckeditor5/ckeditor5.css';

const Settings = () => {
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const timerRef = useRef(null);
    const [templateDaysInviteLinkActive] = useState([1,2,3,4,5,6,7,8,9,10]);
    const [role, setRole] = useState([]);
    const [filteredrole, setFilteredRole] = useState([]);
    const [searchItem, setSearchItem] = useState("");
    const [permission, setpermission] = useState([]);
    const [isTextSelected, setIsTextSelected] = useState(false);
    const [selectedPerm, setSelectedPerm] = useState([]);
    const [namerole, SetNameRole] = useState([]);
    const [descrole, setDescrole] = useState("");
    const [idSelectedRole, setIdSelectedRole] = useState("");
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [openModalAllow, setOpenModalAllow] = useState(false);
    const [modalIsOpenSettingsRoles, setIsOpenSettingsRole] = useState(false);
    const [modalIsOpenSettingsRoles2, setIsOpenSettingsRole2] = useState(false);
    const [checkPermissionRole, setPermissionRole] = useState(false);
    const [canChangeRole, setCanChangeRole] = useState(false);
    const [canViewUser, setCanViewUser] = useState(false);
    const [canDeleteGroup, setCanDeleteGroup] = useState(false);
    const [createusermodal, setIsOpenCreateUser] = useState(false);
    const [post, setPost] = useState([]);
    const [selectedpost, setselectedpost] = useState([]);
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [nameuser, setNameUser] = useState("");
    const [lastname, setLastName] = useState("");
    const [patronymic, setPatronymic] = useState("");
    const [emailuser, setEmailUser] = useState("");
    const [telegramid, setTelegramId] = useState("");
    const [canAddUser, setCanAddUser] = useState(false);
    const [canViewPost, setCanViewPost] = useState(false);
    const [canAddPost, setCanAddPost] = useState(false);
    const [canInviteUser, setCanInviteUser] = useState(false);
    const [loginUserInviteOrganization, setLoginUserInviteOrganization] = useState();
    const [canViewLabel, setCanViewLabel] = useState(false);
    const [modalIsOpenPost, setIsOpenPost] = useState(false);
    const [namepost, setNamePost] = useState("");
    const [flameDaysTask, setFlameDaysTask] = useState("");
    const [organizationId, setOrganizationId] = useState("");
    const [useUsername, setUseUsername] = useState();
    const [isOpenModalTemplateTask, setIsOpenModalTemplateTask] = useState(false);
    // const [isOpenModalDetailTemplateTask, setIsOpenModalDetailTemplateTask] = useState(false);
    const [templateTask, setTemplateTask] = useState();
    const [selectedTemplateTask, setSelectedTemplateTask] = useState();
    const [selectedAllPermission, setSelectedAllPermission] = useState(false);
    const [label, setLabel] = useState([]);
    const [inviteLink, setInviteLink] = useState("");
    const [dateEndActiveInviteLink, setDateEndActiveInvitelink] = useState();
    const [performer, setPerformer] = useState([]);
    const [selectedlabels, setSelectedLabels] = useState([]);
    const [selectedperformers, setSelectedPerformers] = useState([]);
    const [selectedTemplateTaskTitle, setSelectedTemplateTaskTitle] = useState("");
    const [selectedTemplateTaskDescription, setSelectedtemplateTaskDescription] = useState("");
    const [selectedTemplateTaskPriority, setSelectedTemplateTaskPriority] = useState();
    const [modalIsOpenCreateLink, setModalIsOpenCreateLink] = useState(false);
    const [countDaysActiveLink, setCountDaysActivelink] = useState(1);
    const [acceptCreateTaskInStatusCompleted, setAcceptCreateTaskInStatusCompleted] = useState(false);

    const handleCheckboxChangePerformer = (performer) => {
        if (selectedperformers.includes(performer)) {
            setSelectedPerformers(selectedperformers.filter((selectedPerformer) => selectedPerformer !== performer));
        } else {
            setSelectedPerformers([...selectedperformers, performer]);
        }
    };

    const handleOpenModalCreateLink = () => {
        api.get(`${apiUrl}api/v1/organizations/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setOrganizationId(response.data[0].id);
                if (Object.keys(response.data[0].invite_link).length !== 0) {
                    setInviteLink(response.data[0].invite_link.link);
                    setDateEndActiveInvitelink(format(new Date(response.data[0].invite_link.date_end_active), 'dd-MM-yyyy'));
                }
                setModalIsOpenCreateLink(!modalIsOpenCreateLink);
            }
        }).catch(error =>{
            const status = error.response ? error.response.status : null;
            if (error.response.status === 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
            }
        });
    };

    const handleCheckboxChangeLabel = (label) => {
        if (selectedlabels.includes(label)) {
            setSelectedLabels(selectedlabels.filter((selectedLabel) => selectedLabel !== label));
        } else {
            setSelectedLabels([...selectedlabels, label]);
        }
    };

    function fetchAllUsersForTemplate(page = 1, allUsers = []) {
        api.get(`${apiUrl}api/identity/users/`, {
            params: { page: page },
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                const fetchedUsers = response.data;
                const updatedUsersList = allUsers.concat(fetchedUsers);
                if (response.data.next) {
                    fetchAllUsersForTemplate(page + 1, updatedUsersList);
                } else {
                    setPerformer(updatedUsersList);
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleOpenModalDetailTemplateTask = (event, templateTask) => {
        setSelectedTemplateTask(templateTask);
        setSelectedtemplateTaskDescription(templateTask.description);
        setSelectedTemplateTaskTitle(templateTask.title);
        setSelectedTemplateTaskPriority(templateTask.priority);
        fetchAllUsersForTemplate();
        setSelectedLabels(templateTask.label.split(',').map(Number));
        setSelectedPerformers(templateTask.performer.split(','));
        api.get(`${apiUrl}api/v1/labels/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setLabel(response.data);
            }
        }).catch(error =>{
            const status = error.response ? error.response.status : null;
            if (error.response.status === 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
            }
        });
        // setIsOpenModalDetailTemplateTask(true);
    };
    
    const handleChangeFlameDays = (event) => {
        const inputValue = event.target.value;
        if (/^[0-9]*$/.test(inputValue)) {
            setFlameDaysTask(inputValue);
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            if (flameDaysTask !== event.target.value) {
                if (event.target.value) {
                    api.patch(`${apiUrl}api/v1/organizations/${organizationId}/`, {
                        'setting_flame_task': event.target.value,
                    },{
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                    }).then((response) => {
                        if (response.status === 200) {
                            alert('Успешно обновленно');
                            setFlameDaysTask(event.target.value);
                        }
                    }).catch(error => {
                        console.error(error);
                    });
                }
            }
        }, 500);
    };

    function closeModal() {
        setIsOpenCreateUser(false);
        setIsOpenSettingsRole(false);
        setIsOpenSettingsRole2(false);
        setOpenModalAllow(false);
        setIsOpenPost(false);
        setModalIsOpenCreateLink(false);
        // setIsOpenModalDetailTemplateTask(false);
        setIsOpenModalTemplateTask(false);
        setSelectedPerm("");
        setSelectedUser("");
    };

    const openModalAllowAccess = () => {
        closeModal();
        fetchAllUsers();
    };

    const handleTextareachange = (event) => {
        setSearchItem(event.target.value);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            setIsTextSelected(false);
            if (event.target.value) {
                const regex = new RegExp(searchItem, "i");
                const filtered = role.filter((role) => regex.test(role.name));
                setFilteredRole(filtered);
            } 
            else {
                setFilteredRole(role);
            }
        }, 500);
    };
    const handleDescrolechange = (event) => {
        setDescrole(event.target.value);
    };
    const handleLoginChange = (event) => {
        if (event.target.value.length <= 50) {
            setLogin(event.target.value);
        } else {
            return;
        }
    };
    const handleNamePostChange = (event) => {
        if (event.target.value.length <= 200) {
            setNamePost(event.target.value);
        } else {
            return;
        }
    }
    const handlePasswordChange = (event) => {
        if (event.target.value.length <= 50) {
            setPassword(event.target.value);
        } else {
            return;
        }
    };
    const handleNameUserChange = (event) => {
        if (event.target.value.length <= 100) {
            setNameUser(event.target.value);
        } else {
            return;
        }
    };
    const handleLastNameChange = (event) => {
        if (event.target.value.length <= 100) {
            setLastName(event.target.value);
        } else {
            return;
        }
    };
    const handlePatronymicChange = (event) => {
        if (event.target.value.length <= 100) {
            setPatronymic(event.target.value);
        } else {
            return;
        }
    };
    const handleEmailUserChange = (event) => {
        if (event.target.value.length <= 100) {
            setEmailUser(event.target.value);
        } else {
            return;
        }
    };

    function handleOpenModalCreateRole() {
        setSelectedAllPermission(false);
        api.get(`${apiUrl}api/identity/permissions/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200) {
                setpermission(response.data);
                setIsOpenSettingsRole(true);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    function handleOpenModalCreateUser() {
        api.get(`${apiUrl}api/identity/post/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status===200){
                setPost(response.data);
                setIsOpenCreateUser(true);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    function fetchAllUsers(page = 1, allUsers = []) {
        api.get(`${apiUrl}api/identity/users/`, {
            params: { page: page },
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                const fetchedUsers = response.data;
                const updatedUsersList = allUsers.concat(fetchedUsers);
                if (response.data.next) {
                    fetchAllUsers(page + 1, updatedUsersList);
                } 
                else {
                    setUsers(updatedUsersList);
                    updatedUsersList.forEach(i => {
                        if (i.group.includes(idSelectedRole)) {
                            setSelectedUser(prevSelectedUsers => [...prevSelectedUsers, i.id]);
                        }
                    });
                    setOpenModalAllow( true );
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleSetTruPerm = (data) => {
        let newSelectedPerm = [...selectedPerm];
        if (data === true) {
            setSelectedPerm(permission.map(item => item.id));
            setSelectedAllPermission(true);
        }
        else {
            setSelectedAllPermission(false);
            if (data.id) {
                if (newSelectedPerm.includes(data.id)) {
                    newSelectedPerm = newSelectedPerm.filter(item => item !== data.id);
                } 
                else {
                    newSelectedPerm.push(data.id);
                }
                setSelectedPerm(newSelectedPerm);
            }
            else {
                setSelectedPerm([]);
            }
        }
    };

    const handleSetTruRole = (data) => {
        let newSelectedRole = [...selectedUser];
        if (newSelectedRole.includes(data.id)) {
            newSelectedRole = newSelectedRole.filter(item => item !== data.id);
        } 
        else {
            newSelectedRole.push(data.id);
        }
        setSelectedUser(newSelectedRole);
    };

    const handleDeleteRole = () => {
        api.delete(`${apiUrl}api/identity/groupsuser/${idSelectedRole}/`,{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 204) {
                closeModal();
                alert("Успешно удалено");
                updateAll();
            }
        })
        .catch(error =>{
            console.error(error);
            if (error.response.status === 403) {
                alert(error.response.data.detail);
            }
        });
    };

    const handleCreateRole = () => {
        if (namerole === ""){
            alert("Введите наименование роли!");
        }
        else {
            api.post(`${apiUrl}api/identity/groupsuser/`, {
                name: namerole,
                name_group: namerole,
                permissions: selectedPerm
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status===201){
                    setSelectedPerm("");
                    SetNameRole("");
                    closeModal();
                    updateAll();
                }
            }).catch(error => {
                console.error(error);
                if (error.response.status === 403) {
                    alert(error.response.data.detail);
                }
            });
        }
    };

    const handleUpdateRoleAddUsers = () => {
        api.put(`${apiUrl}api/identity/users/${idSelectedRole}/update-users/`, {
            user_id: selectedUser,
            group: idSelectedRole
        }, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status===200){
                setSelectedPerm("");
                closeModal();
                updateAll();
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleUpdateRole = () => {
        api.put(`${apiUrl}api/identity/groupsuser/${idSelectedRole}/`, {
            name: namerole,
            name_group: namerole,
            permissions: selectedPerm
        }, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status===200) {
                setSelectedPerm("");
                closeModal();
                updateAll();
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeSelectedTemplateTaskTitle = (event) => {
        setSelectedTemplateTaskTitle(event.target.value);
    };

    const handleTextareaClick = (event) => {
        const textarea = event.target;
        if (!isTextSelected) {
            textarea.focus();
            textarea.setSelectionRange(0, textarea.value.length);
            setIsTextSelected(true);
        }
        else{
            setIsTextSelected(false);
        }
    };

    const handleTextAreaDescBlur = (data) => {
        setIsTextSelected(false);
        if (descrole !== "") {
            api.put(`${apiUrl}api/identity/groupsuser/${data.role_id}/`, {
                description: descrole
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setDescrole("");
                }
            }).catch(error => {
            });
        }
        else{
            setDescrole("");
        }
    };

    function updateAll() {
        const loadBoards = async () => {
            await api.get(`${apiUrl}api/v1/user/permissions/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    if (response.data.includes('Can change group')) {
                        setCanChangeRole(true);
                    }
                    if (response.data.includes('Can add group') && response.data.includes('Can view permission')) {
                        setPermissionRole(true);
                    }
                    if (response.data.includes('Can add Должность')) {
                        setCanAddPost(true);
                    }
                    if (response.data.includes('Can add Пользователь')) {
                        setCanAddUser(true);
                    }
                    if (response.data.includes('Can view Должность')) {
                        setCanViewPost(true);
                    }
                    if (response.data.includes('Can view Пользователь')) {
                        setCanViewUser(true);
                    }
                    if (response.data.includes('Can delete group')) {
                        setCanDeleteGroup(true);
                    }
                    if (response.data.includes('Can view Метка')) {
                        setCanViewLabel(true);
                    }
                    if (response.data.includes('owner_organization')) {
                        setCanChangeRole(true);
                        setPermissionRole(true);
                        setCanAddPost(true);
                        setCanAddUser(true);
                        setCanViewPost(true);
                        setCanViewUser(true);
                        setCanDeleteGroup(true);
                        setCanInviteUser(true);
                    }
                }
            }).catch(error => {
            });
            await api.get(`${apiUrl}api/v1/organizations/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                if (response.status===200){
                    setOrganizationId(response.data[0].id);
                    setFlameDaysTask(response.data[0].setting_flame_task);
                    setUseUsername(response.data[0].use_username);
                    setAcceptCreateTaskInStatusCompleted(response.data[0].allow_create_task_in_status_completed);
                }
            }).catch(error => {
            });
        }
        loadBoards();
        api.get(`${apiUrl}api/identity/groupsuser/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status===200){
                setRole(response.data);
                setFilteredRole(response.data);
            }
        }).catch(error => {
            if (error.response.status === 403) {
            }
        });
        api.get(`${apiUrl}api/identity/permissions/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200){
                setpermission(response.data);
            }
        }).catch(error => {
            if (error.response.status === 403) {
                setPermissionRole(false);
            }
        });
    };

    const handleUpdateInviteLink = () => {
        if (countDaysActiveLink) {
            api.patch(`${apiUrl}api/v1/organizations/${organizationId}/update-invite-link/`, {
                'date_end_active': countDaysActiveLink
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    try {
                        navigator.clipboard.writeText(response.data.invite_link.link);
                        alert("Ссылка скопирована в буфер обмена");
                    } catch (err) {
                        console.error('Не удалось скопировать: ', err);
                    }
                    setInviteLink(response.data.invite_link.link);
                    setDateEndActiveInvitelink(format(new Date(response.data.invite_link.date_end_active), 'dd-MM-yyyy'));
                }
            }).catch(error => {
                console.error(error);
            });
        }
        else {
            alert("Укажите количество суток действия ссылки");
        }
    };

    const handleCreateUser = (event) => {
        event.preventDefault();
        if (selectedpost.length > 0 && login !== "" && password !== "" && emailuser !== "") {
            if (telegramid) {
                api.post(`${apiUrl}api/identity/users/`, {
                    username: login,
                    password: password,
                    first_name: nameuser,
                    last_name: lastname,
                    patronymic: patronymic,
                    post: selectedpost,
                    email: emailuser,
                    user_telegram_id: telegramid,
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                    if (response.status === 201){
                        setLogin("");
                        setPassword("");
                        setNameUser("");
                        setLastName("");
                        setPatronymic("");
                        setEmailUser("");
                        setTelegramId("");
                        setselectedpost([]);
                        closeModal();
                    }
                }).catch(error => {
                    const status = error.response ? error.response.status : null;
                    if (error.response.status === 400) {
                        if (error.response.data.email) {
                            alert(`Код ошибки: ${status}, ${error.response.data.email}`, error);
                        }
                        if (error.response.data.error) {
                            alert(`Код ошибки: ${status}, ${error.response.data.error}`, error);
                        }
                        if (error.response.data.post) {
                            alert(`Код ошибки: ${status}, Выбери должность нормально`, error);
                        }
                    }
                });
            }
            else {
                api.post(`${apiUrl}api/identity/users/`, {
                    username: login,
                    password: password,
                    first_name: nameuser,
                    last_name: lastname,
                    patronymic: patronymic,
                    post: selectedpost,
                    email: emailuser,
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                    if (response.status === 201){
                        setLogin("");
                        setPassword("");
                        setNameUser("");
                        setLastName("");
                        setPatronymic("");
                        setEmailUser("");
                        setTelegramId("");
                        setselectedpost([]);
                        closeModal();
                    }
                }).catch(error => {
                    const status = error.response ? error.response.status : null;
                    if (error.response.status === 400) {
                        if (error.response.data.email) {
                            alert(`Код ошибки: ${status}, ${error.response.data.email}`, error);
                        }
                        if (error.response.data.error) {
                            alert(`Код ошибки: ${status}, ${error.response.data.error}`, error);
                        }
                        if (error.response.data.post) {
                            alert(`Код ошибки: ${status}, Выберите должность`, error);
                        }
                    }
                });
            }
        }
        else{
            alert("Заполните данные.");
        }
    };

    const handleOpenInfoForRole = (data) => {
        setSelectedPerm(data.perm_id);
        SetNameRole(data.role_name);
        setIdSelectedRole(data.role_id);
        api.get(`${apiUrl}api/identity/permissions/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200){
                setpermission(response.data);
                if (data.perm_id.length === response.data.length) {
                    setSelectedAllPermission(true);
                }
                else {
                    setSelectedAllPermission(false);
                }
                setIsOpenSettingsRole2(true);
            }
        }).catch(error => {
            console.error(error);
            const status = error.response ? error.response.status : null;
            if (error.response.status === 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
            }
        });
    };

    useEffect(() => {
        updateAll();
        // eslint-disable-next-line
    }, []);

    const handlePostChange = (event) =>{
        const groups = event.target.value;
        if (groups === "Должность *") {
            // setFilteredTask(task)    
        }
        else{
            setselectedpost(groups);
        }
    };

    const handleCreatePost = (event) => {
        event.preventDefault();
        api.post(`${apiUrl}api/identity/post/`, {
            name: namepost
        }, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 201){
                setNamePost("");
                closeModal();
            }
        }).catch(error => {
            const status = error.response ? error.response.status : null;
            if (error.response.status === 400) {
                if (error.response.data.error) {
                    alert(`Код ошибки: ${status}, ${error.response.data.error}`, error);
                }
            }
        });
    };

    const handleCopyInvitelink = () => {
        try {
            navigator.clipboard.writeText(inviteLink);
            alert("Ссылка скопирована в буфер обмена");
        } catch (err) {
            console.error('Не удалось скопировать: ', err);
        }
    };

    const handleChangeUseUsername = (use_username) => {
        setUseUsername(!useUsername);
        api.patch(`${apiUrl}api/v1/organizations/${organizationId}/`, {
            'use_username': use_username,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                alert("Успешно обновлено");
                window.location.href = '/settings';
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeAcceptCreateTaskInStatusCompleted = (accept_create_task_completed) => {
        setAcceptCreateTaskInStatusCompleted(!acceptCreateTaskInStatusCompleted);
        api.patch(`${apiUrl}api/v1/organizations/${organizationId}/`, {
            'allow_create_task_in_status_completed': accept_create_task_completed,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                alert("Успешно обновлено");
                window.location.href = '/settings';
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleOpenTemplateModal = (event) => {
        event.preventDefault();
        setSelectedTemplateTask("");
        closeModal();
        api.get(`${apiUrl}api/v1/template-task/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setTemplateTask(response.data);
                if (response.data.length > 0) {
                    setIsOpenModalTemplateTask(true);
                }
                else {
                    alert("Шаблонов задач нет");
                }
            }
        }).catch(error => {
            const status = error.response ? error.response.status : null;
            if (error.response.status === 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
            }
        });
    };

    const handleChangeTemplateTask = () => {
        api.patch(`${apiUrl}api/v1/template-task/${selectedTemplateTask.id}/`, {
            title: selectedTemplateTaskTitle,
            description: selectedTemplateTaskDescription,
            priority: selectedTemplateTaskPriority,
            label: `${selectedlabels}`,
            performer: `${selectedperformers}`,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                closeModal();
                alert('Успешно обновленно');
            }
        }).catch(error => {
        });
    };

    const handleDeleteSelectedTemplateTask = () => {
        const result = window.confirm(`Действительно хотите удалить шаблон задачи ${selectedTemplateTask.title}`);
        if (result) {
            api.delete(`${apiUrl}api/v1/template-task/${selectedTemplateTask.id}/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 204) {
                    alert("Успешно удалено");
                    closeModal();
                }
            }).catch(error => {
                const status = error.response ? error.response.status : null;
                if (error.response.status === 403) {
                    alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
                }
            });
        }
    };

    const handleSendNotificationInviteLink = () => {
        if (loginUserInviteOrganization) {
            api.get(`${apiUrl}api/v1/organizations/${organizationId}/invite-user/`, {
                params: {
                    'username': loginUserInviteOrganization
                },
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    alert("Приглашение было отправлено");
                }
            }).catch(error => {
                const status = error.response ? error.response.status: null;
                if (error.response.status === 403 && error.response.data.detail === 'LinkNotActive') {
                    alert(`Код ошибки: ${status}, Время действия ссылки истекло, обновите ссылку и повторите попытку.`, error);
                }
                if (error.response.status === 403 && error.response.data.detail === 'UserExistsInThisOrganization') {
                    alert(`Код ошибки: ${status}, Пользователь уже состоит в текущей организации.`, error);
                }
                if (error.response.status === 403 && error.response.data.detail === 'UserNoActiveNotificationInviteOrganization') {
                    alert(`Код ошибки: ${status}, Пользователь недоступен для приглашения в другие организации.`, error);
                }
                if (error.response.status === 500 && error.response.data.detail === 'NotAcceptedInviteOrganization') {
                    alert(`Код ошибки: ${status}, Приглашение не было принято.`, error);
                }
                if (error.response.status === 404 && error.response.data.detail === 'UserNotFound') {
                    alert(`Код ошибки: ${status}, Пользователь не найден.`, error);
                }
            });
        }
        else {
            alert("Заполните логин пользователя");
        }
    };

    const handleEditorChange = (data) => {
        setSelectedtemplateTaskDescription(data);
    };

    const handlePriorityChange = (event) => {
        setSelectedTemplateTaskPriority(event.target.value);
    };

    var screenHeight = window.innerHeight;

    document.title = "Настройки - ТвояЗадача";

    return (
        <div className='settings-page'>
            <div id="settings-window" style={{maxHeight: screenHeight - 220}}>
                <Modal className="Modal"
                    isOpen={isOpenModalTemplateTask}
                    onRequestClose={closeModal}
                    overlayClassName="Overlay"
                >
                    {selectedTemplateTask ?
                        <div id="div-for-detail-template-task">
                            <div style={{marginBottom: '15px'}}>
                                <label id="performerlabel-create-task">
                                    Изменить шаблон
                                </label>
                            </div>
                            <div id="div-detail-template-task">
                                <input id="search-group"
                                    value={selectedTemplateTaskTitle}
                                    placeholder='Заголовок задачи'
                                    onChange={(event) => handleChangeSelectedTemplateTaskTitle(event)}
                                />
                                <div id="div-ckeditor-template-task">
                                    {/* <CKEditor
                                        extraAllowedContent='*(*);*{*}'
                                        editor={ ClassicEditor }
                                        disabled={false}
                                        data={selectedTemplateTaskDescription}
                                        config={
                                            editorConfig
                                        }
                                        onChange={ ( event, editor ) => {
                                            handleEditorChange(event, editor)
                                        }}
                                    /> */}
                                    <CKeditor5
                                        onEditorChange={(data) => handleEditorChange(data)}
                                        editorDisabled={true}
                                        descriptiontask={selectedTemplateTaskDescription}
                                    />
                                </div>
                                <div style={{display: 'flex'}}>
                                    {canViewLabel ?
                                        <div id="div-with-performer-and-labels">
                                            <label id="performerlabel-change">Метка</label>
                                            <div style={{height: '200px', overflowY: 'auto'}}>
                                                {label.map((labeli, labelindex) => (
                                                    <label id="div-with-label" key={labelindex}>
                                                        <div style={{
                                                            background: labeli.color, 
                                                            height: "25px",
                                                            width: "25px",
                                                            borderRadius: "5px",
                                                            }}
                                                        >
                                                            <label id="name-labels"
                                                                key={labelindex} 
                                                                style={{fontFamily: 'Roboto', fontSize: '16px'}}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    key={labelindex}
                                                                    name="performers"
                                                                    onChange={() => handleCheckboxChangeLabel(labeli.id)}
                                                                    checked={selectedlabels.includes(labeli.id)}
                                                                />
                                                                <span style={{marginLeft: "25px", textWrap: "nowrap", overflow: "hidden"}}>
                                                                    {labeli.name}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </label>
                                                ))}
                                            </div>                
                                        </div>: null
                                    }
                                    <div id="div-with-performer-and-labels">
                                        <label id="performerlabel-change">Исполнитель</label>
                                        <div style={{height: '200px', overflowY: 'auto'}}>
                                            {performer.map((performer, index) => (
                                                <label id="div-with-label" key={index}>
                                                    {performer.image ?
                                                        <img id="photo-profile-for-performer"
                                                            src={performer.image}
                                                            alt=""
                                                        />:
                                                        <img id="not-photo-profile-for-performer"
                                                            src={apiUrlFrontend + "person-square.svg"}
                                                            alt=""
                                                        />
                                                    }
                                                    <input
                                                        type="checkbox"
                                                        key={index}
                                                        name="performers"
                                                        onChange={() => handleCheckboxChangePerformer(performer.username)}
                                                        checked={selectedperformers.includes(performer.username)}
                                                    />
                                                    <span style={{marginLeft: "10px", fontFamily: 'Roboto', fontSize: '16px', fontWeight: 'bold'}}>
                                                        {performer.formated_fio ?
                                                            performer.formated_fio:
                                                            performer.username
                                                        }
                                                    </span>
                                                </label>
                                            ))}
                                        </div>    
                                    </div>
                                    <div style={{marginLeft: "10px", display: 'flex', flexDirection: 'column', justifyItems: 'center'}}>
                                        <label id="performerlabel" style={{marginLeft: '0px '}} >Приоритет</label>
                                        <select
                                            id="post-select-change" style={{marginTop: '-15px', height: '52px'}}
                                            value={selectedTemplateTaskPriority}
                                            onChange={handlePriorityChange}
                                        >
                                            {Array.from({ length: 10 }, (_, index) => (
                                                <option key={index + 1} value={index + 1}>
                                                    {index + 1}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div style={{display: 'flex', marginTop: '10px'}}>
                                    <button id="change-template-task"
                                        onClick={() => handleChangeTemplateTask()}
                                    >
                                        Изменить
                                    </button>
                                    <button id="delete-template-task"
                                        onClick={() => handleDeleteSelectedTemplateTask()}
                                    >
                                        Удалить
                                    </button>
                                </div>
                            </div>
                        </div>:
                        <div id="div-for-template-task">
                            <label id="performerlabel-create-task-shab">
                                Шаблоны задач
                            </label>
                            <div id="div-with-template-card">
                                {templateTask ? templateTask.map((tt, ttindex) => (
                                    <button id="template-card" key={ttindex}
                                        onClick={(event) => handleOpenModalDetailTemplateTask(event, tt)}
                                    >
                                        {tt.title}
                                    </button>)): null
                                }
                            </div>
                        </div>
                    }
                </Modal>
                <Modal
                    className="Modal"
                    isOpen={modalIsOpenSettingsRoles}
                    onRequestClose={closeModal}
                    overlayClassName="Overlay"
                >
                    <label id="performerlabel-create-task">
                        Создание роли
                    </label>
                    <div id="up-role-modal-div">
                        <input id="create-name-role"
                            placeholder="Введите наименование роли"
                            onChange={(event) => SetNameRole(event.target.value)}
                            maxLength={80}
                        />
                    </div>
                    <div id="checkbox-with-perm">
                        <div id="name-per">
                            <label id="selected-all-permission">
                                Выбрать все
                            </label>
                            <label class="checkbox style-e" style={{marginLeft: 'auto', marginTop: '10px'}}>
                                <input id="check-group"
                                    type="checkbox"
                                    onChange={() => handleSetTruPerm(!selectedAllPermission)}
                                    checked={selectedAllPermission}
                                />
                                <div class="checkbox__checkmark"></div>
                            </label>
                        </div>
                    </div>
                    <div id="div-with-permission-role">
                        {permission.map((pm, permindex) => (
                            <div id="checkbox-with-perm" index={permindex}>
                                <div id="name-per">
                                    <label id="namePerm">
                                        {pm.name}
                                    </label>
                                </div>
                                <label class="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleSetTruPerm({id: pm.id})}
                                        checked={selectedPerm.includes(pm.id)}
                                    />
                                    <div class="checkbox__checkmark"></div>
                                </label>
                            </div>
                        ))}
                    </div>
                    <button id="create-role-button"
                        onClick={handleCreateRole}
                    >
                        Создать
                    </button>
                </Modal>
                <Modal
                    className="Modal"
                    isOpen={modalIsOpenSettingsRoles2}
                    onRequestClose={closeModal}
                    overlayClassName="Overlay"
                >
                    <label id="performerlabel-create-task">
                        Изменение роли
                    </label>
                    <div id="up-role-modal-div">
                        <textarea id="nameRole"
                            style={{resize: 'none'}}
                            placeholder="Введите наименование роли"
                            onClick={handleTextareaClick}
                            onChange={(event) => SetNameRole(event.target.value)}
                            maxLength={80}
                        >
                            {namerole}
                        </textarea>
                        <div id="div-edit-role-btn">
                            <button id="update-role-button"
                                onClick={handleUpdateRole}
                            >
                                Обновить
                            </button>
                            {canViewUser ?
                                <button id="open-role-btn"
                                    onClick={openModalAllowAccess}
                                >
                                    Предоставить доступ
                                </button>: null
                            }
                            {canDeleteGroup ?
                                <button id="delete-role-btn"
                                    onClick={() => handleDeleteRole()}
                                >
                                    Удалить роль
                                </button>: null
                            }
                        </div>
                    </div>
                    <div id="checkbox-with-perm">
                        <div id="name-per">
                            <label id="selected-all-permission">
                                Выбрать все
                            </label>
                        </div>
                        <label class="checkbox style-e">
                            <input id="check-group"
                                type="checkbox"
                                onChange={() => handleSetTruPerm(!selectedAllPermission)}
                                checked={selectedAllPermission}
                            />
                            <div class="checkbox__checkmark"></div>
                        </label>
                    </div>
                    <div id="div-with-permission-role">
                        {permission.map((pm, permindex) => (
                            <div id="checkbox-with-perm" index={permindex}>
                                <div id="name-per">
                                    <label id="namePerm">
                                        {pm.name}
                                    </label>
                                </div>
                                <label class="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleSetTruPerm({id: pm.id})}
                                        checked={selectedPerm.includes(pm.id)}
                                    />
                                    <div class="checkbox__checkmark"></div>
                                </label>
                            </div>
                        ))}
                    </div>
                </Modal>
                <Modal
                    className="Modal"
                    isOpen={openModalAllow}
                    onRequestClose={closeModal}
                    overlayClassName="Overlay"
                >
                    <label id="performerlabel-create-task-label">
                        Предоставить доступ к {namerole}
                    </label>
                    <div id='div-with-names-and-checkbox'>
                        {users.map((us, usindex) => (
                            <div id="checkbox-with-perm" index={usindex}>
                                {getOnlineUser(us.id)}
                                {us.image ?
                                    <img id="image-users"
                                        src={us.image}
                                        alt=''
                                    />:
                                    <img id="not-found-image-users"
                                        src={apiUrlFrontend + "person-square.svg"}
                                        alt=''
                                    />
                                }
                                <div id="name-per">
                                    {us.formated_fio ?
                                        <label id="nameuser">
                                            {us.formated_fio.slice(0, 20)}
                                        </label>:
                                        <label id="nameuser">
                                            {us.username.slice(0, 20)}
                                        </label>
                                    }
                                </div>
                                <label class="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleSetTruRole({id: us.id, group_id: us.group})}
                                        checked={selectedUser.includes(us.id)}
                                    />
                                    <div class="checkbox__checkmark"></div>
                                </label>
                            </div>
                        ))}
                    </div>
                    <button id="update-group-button"
                        onClick={handleUpdateRoleAddUsers}
                    >
                        Подтвердить
                    </button>
                </Modal>
                <Modal
                    className="Modal-add-user"
                    isOpen={createusermodal}
                    onRequestClose={closeModal}
                    overlayClassName="Overlay"
                >
                    <label id="performerlabel-create-task">
                        Создать пользователя
                    </label>
                    <form id="create-user-form" onSubmit={(event) => handleCreateUser(event)}>
                        <input
                            type="text"
                            id="create-new-user-input"
                            value={login}
                            onChange={handleLoginChange}
                            placeholder='Логин *'
                            required
                        />
                        <input
                            type="password"
                            id="create-new-user-input"
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder='Пароль *'
                            required
                        />
                        <input
                            type="text"
                            id="create-new-user-input"
                            value={nameuser}
                            onChange={handleNameUserChange}
                            placeholder='Имя'
                        />
                        <input
                            type="text"
                            id="create-new-user-input"
                            value={lastname}
                            onChange={handleLastNameChange}
                            placeholder='Фамилия'
                        />
                        <input
                            type="text"
                            id="create-new-user-input"
                            value={patronymic}
                            onChange={handlePatronymicChange}
                            placeholder='Отчество'
                        />
                        <input
                            type="text"
                            id="create-new-user-input"
                            value={emailuser}
                            onChange={handleEmailUserChange}
                            placeholder='Адрес электронной почты *'
                            required
                        />
                        <select id="post-select" onChange={handlePostChange}>
                            <option>Должность *</option>
                            {post.map((group, index) => (
                                <option key={index} value={group.id}>{group.name}</option>
                            ))}
                        </select>
                        <button id="update-group-button" type="submit">
                            Создать
                        </button>
                    </form>
                </Modal>
                <Modal
                    className="Modal"
                    isOpen={modalIsOpenPost}
                    onRequestClose={closeModal}
                    overlayClassName="Overlay"
                >
                    <label id="jobtitlelabel-create">
                        Создать должность
                    </label>
                    <form id="create-user-form" onSubmit={(event) => handleCreatePost(event)}>
                        <input
                            type="text"
                            id="create-name-role"
                            value={namepost}
                            onChange={handleNamePostChange}
                            placeholder='Наименование должности *'
                            required
                        />
                        <button id="update-group-button" type="submit">
                            Создать
                        </button>
                    </form>
                </Modal>
                <Modal
                    className="Modal" 
                    isOpen={modalIsOpenCreateLink}
                    onRequestClose={closeModal}
                    overlayClassName="Overlay"
                >
                    <label id="performerlabel-create-task" style={{textAlign: 'center'}}>
                        Приглашение пользователей в организацию
                    </label>
                    <div id="div-create-link">
                        <div id="div-with-invite-link">
                            <label id="invite-link">
                                {inviteLink}
                            </label>
                            <img id="copy-link"
                                src={apiUrlFrontend + "copy.svg"}
                                alt=''
                                onClick={() => handleCopyInvitelink()}
                            />
                        </div>
                        <label id="date-end-active-link">
                            Текущая ссылка неактивна с {dateEndActiveInviteLink}
                        </label>
                        <div id="div-with-time-active-link">
                            <label id="label-mini-title">
                                Срок действия ссылки:
                            </label>
                            <select id="select-with-days-active-link"
                                onChange={(e) => setCountDaysActivelink(e.target.value)}
                                defaultValue={1}
                            >
                                {templateDaysInviteLinkActive.map((day, dayIndex) => 
                                    <option>{day}</option>
                                )}
                            </select>
                            <label id="label-mini-title">
                                (количество суток)
                            </label>
                        </div>
                        <input id="login-user-create-link"
                            placeholder="Введите логин пользователя"
                            maxLength={20}
                            onChange={(e) => setLoginUserInviteOrganization(e.target.value)}
                        />
                        <div id="div-with-time-active-link">
                            <button id="send-link-for-user"
                                onClick={() => handleSendNotificationInviteLink()}
                            >
                                Отправить приглашение
                            </button>
                            <label id="hint-for-send-link-user">
                                Отправляет ссылку приглашение выбранному пользователю, если его учетная запись существует в системе.
                            </label>
                        </div>
                        <div id="div-with-time-active-link">
                            <label id="hint-for-send-link-user">
                                При обновлении ссылки, предыдущая перестанет быть активной.
                            </label>
                            <button id="send-link-for-user"
                                onClick={() => handleUpdateInviteLink()}
                            >
                                Обновить ссылку
                            </button>
                        </div>
                    </div>
                </Modal>
                {role.length > 0 ?
                    <div id="div-with-search">
                        <input id="search-group"
                            placeholder="Начните вводить наименование роли"
                            onClick={handleTextareaClick}
                            onChange={handleTextareachange}
                        />            
                    </div>: null
                }
                <label id="hint-for-group">
                    Роль — это набор разрешений на выполнение определенных операций в ТвояЗадача. 
                    Роль назначается на уровне организации и выдается пользователям. 
                    Чтобы просмотреть и настроить роль, нажмите на ее имя в списке.
                    Чтобы предоставить доступ пользователю нажмите на имя роли в списке, 
                    далее нажмите предоставить доступ, выберите нужных пользователей.
                </label>
                <div id='div-for-left-btn-in-settings' style={{display: "flex"}}>
                    <div id="div-right-button">
                        <button id="template-task-button"
                            onClick={(event) => handleOpenTemplateModal(event)}
                        >
                            Шаблон задач
                        </button>    
                        {canAddPost ?
                            <button id="create-new-post"
                                onClick={() => setIsOpenPost(!modalIsOpenPost)}
                            > 
                                Новая должность
                            </button>: null
                        }
                        {canAddUser && canViewPost ?
                            <button id="create-new-user"
                                onClick={() => handleOpenModalCreateUser()}
                            > 
                                Новый пользователь
                            </button>: null
                        }
                        {checkPermissionRole ?
                            <button id="create-new-role"
                                onClick={() => handleOpenModalCreateRole()}
                            > 
                                Новая роль
                            </button>: null
                        }
                        {canInviteUser ?
                            <button id="create-new-link"
                                onClick={() => handleOpenModalCreateLink()}
                            > 
                                Пригласить пользователя
                            </button>: null
                        }
                    </div>
                    <div id='div-for-right-btn-in-settings'>
                        <div id="div-change-username-or-fio">
                            <label id="change-username-on-fio">
                                Отображать Фамилию И.О.
                            </label>
                            <div id="custom-checkbox-settings">
                                <label class="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleChangeUseUsername(!useUsername)}
                                        checked={useUsername}
                                    />
                                    <div class="checkbox__checkmark"></div>
                                </label>
                            </div>
                        </div> 
                        <div id="div-change-username-or-fio">
                            <label id="change-username-on-fio">
                                Разрешить создавать задачи в статусе выполнено
                            </label>
                            <div id="custom-checkbox-settings">
                                <label class="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleChangeAcceptCreateTaskInStatusCompleted(!acceptCreateTaskInStatusCompleted)}
                                        checked={acceptCreateTaskInStatusCompleted}
                                    />
                                    <div class="checkbox__checkmark"></div>
                                </label>
                            </div>
                        </div>
                        <div style={{display: 'flex', width: '180px'}}>
                            <div id="task-flame-in-days">
                                Считать задачу горящей за
                            </div>
                            <input id="task-flame-in-days-txt"
                                placeholder='3'
                                value={flameDaysTask}
                                onChange={handleChangeFlameDays}
                            />
                            <div id="task-flame-in-days-right">
                                суток
                            </div>
                        </div> 
                    </div>
                    {role.length > 0 ?
                        <div id="div-with-all-element-role">
                            <label id='performerlabel-create-task'>Список ролей</label>
                            <div id='div-for-role-and-desc'>
                            </div>
                            {filteredrole.length > 0 ?
                                filteredrole.map((rl, roleindex) => (
                                    <div id="div-with-role-and-desc" index={roleindex}>
                                        <div id="name-role-and-count-permission">
                                            {canChangeRole ?
                                                <button id="open-settings-role"
                                                    onClick={() => handleOpenInfoForRole({perm_id: rl.permissions, role_name: rl.name_group, role_id: rl.role_id})}
                                                >
                                                    {rl.name_group}
                                                </button>: 
                                                <button id="open-settings-role">
                                                    {rl.name_group}
                                                </button>
                                            }
                                            <label id="count-permission">
                                                Права доступа {rl.permissions.length}
                                            </label>
                                        </div>
                                        {canChangeRole ?
                                            <textarea id="desc-role"
                                                type="text"
                                                placeholder="Добавьте описание"
                                                onClick={handleTextareaClick}
                                                onChange={handleDescrolechange}
                                                onBlur={() => handleTextAreaDescBlur({role_id: rl.role_id})}
                                            >
                                                {rl.description}
                                            </textarea>: 
                                            <textarea id="desc-role"
                                                type="text"
                                                readOnly='true'
                                                placeholder="Добавьте описание"
                                                onClick={handleTextareaClick}
                                            >
                                                {rl.description}
                                            </textarea>
                                        }
                                    </div>
                                )):
                                <div id="not-found-role-label">
                                    Не найдено
                                </div>
                            }
                        </div>: null
                    }
                </div>
            </div>
        </div>
    );
}

export default Settings;